import { Row, Col, Card, Checkbox, Space } from "antd";
import ReactECharts from 'echarts-for-react';
import { customAxios } from "../../utils";

import Layout from "../../component/LayoutCom";
import Header from '../../component/HeaderTab'
import DatePicker from '../../component/DatePicker'

import './index.scss'
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import $ from "lodash"

//联合调频
export default function View() {
    const [timeRange, settTimeRange] = useState(null)
    const AgcOption = {
        grid: {
            top: 26,
            left:"15%",
            bottom: 20,
            right: "15%"
        },
        xAxis: {
            type: 'category',
            data: []
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [],
                type: 'line',
                showSymbol: false
                //todo
            }
        ],
        tooltip: {
            show: true,
            trigger: "axis"
        },
        dataZoom: {
            type: "slider",
            height:15
        }
    }
    const [AgcOptionRight, setAgcOptionRight] = useState([
        {
            ...AgcOption,
            yAxis: {
                type: "value",
                splitNumber: 4
            },
            legend:{
                data:["优化后k1","优化前k1"],
                orient: "vertical",
                right: 0
            },
        },
        {
            ...AgcOption,
            yAxis: {
                type: "value",
                splitNumber: 3
            },
            legend:{
                data:["优化后k2","优化前k2"],
                orient: "vertical",
                right: 0
            },
        },
        {
            ...AgcOption,
            yAxis: {
                type: "value",
                splitNumber: 3
            },
            legend:{
                data:["优化后k3","优化前k3"],
                orient: "vertical",
                right: 0
            },
        },
        {
            ...AgcOption,
            yAxis: {
                type: "value",
                splitNumber: 3
            },
            legend:{
                data:["优化后kp","优化前kp"],
                orient: "vertical",
                right: 0
            },
        },
        {
            ...AgcOption,
            tooltip: {
                ...AgcOption.tooltip,
                valueFormatter: (value) => value + "MW"
            },
            yAxis: {
                type: 'value',
                name: "MW",
                splitNumber: 3
            },
            legend:{
                data:["优化后里程","优化前里程"],
                orient: "vertical",
                right: 0
            },
        }
    ])
    const [AgcOptionProfit, setAgcOptionProfit] = useState({
        ...AgcOption,
        tooltip: {
            ...AgcOption.tooltip,
            valueFormatter: (value) => value + "元"
        },
        legend:{
            data:["优化后收益","优化前收益"],
            orient: "vertical",
            right: 0
        },
        yAxis: {
            type: 'value',
            name: "元"
        },
    })
    const [AgcOptionSOC, setAgcOptionSOC] = useState({
        ...AgcOption,
        tooltip: {
            ...AgcOption.tooltip,
            valueFormatter: (value) => value + "%"
        },
        legend:{
            data:["优化后SOC","优化前SOC"],
            orient: "vertical",
            right: 0
        },
        yAxis: {
            type: 'value',
            name: "%"
        }
    })
    const [optionArr, setOptionArr] = useState({
        ...AgcOption,
        tooltip: {
            ...AgcOption.tooltip,
            valueFormatter: (value) => value + "WM"
        },
        legend: {
            name: ["AGC指令", "储能出力", "联合出力", "火电出力","（优化后）储能出力","（优化后）联合出力"],
            orient: "vertical",
            right: 0
        },
        yAxis: {
            type: 'value',
            name: "MW"
        },
    })
    const getData = (type) => {
        customAxios({
            method: "get",
            url: "/agc/history",
            params: {
                start_time: timeRange[0].valueOf() ? timeRange[0].valueOf() : 1091473259792,
                end_time: timeRange[1].valueOf() ? timeRange[1].valueOf() : 1691473259792,
            }
        }).then((res) => {
            let json = res.data
            // let temp=$.cloneDeep(optionArr)
            let temp = JSON.parse(JSON.stringify({
                ...AgcOption,
                tooltip: {
                    ...AgcOption.tooltip,
                    valueFormatter: (value) => value + "WM"
                },
                legend: {
                    data: ["AGC指令", "储能出力", "联合出力", "火电出力","（优化后）储能出力","（优化后）联合出力"],
                    orient: "vertical",
                    right: 0
                },
                yAxis: {
                    type: 'value',
                    name: "MW",
                    scale:true
                },
            }))
            let tempSOC = JSON.parse(JSON.stringify({
                ...AgcOption,
                tooltip: {
                    ...AgcOption.tooltip,
                    valueFormatter: (value) => value + "%"
                },
                legend:{
                    data:["优化后SOC","优化前SOC"],
                    orient: "vertical",
                    right: 0
                },
                yAxis: {
                    type: 'value',
                    name: "%"
                }
            }))
            let time = []
            let arr0 = [], ess_p = [], ess_plant_p = [], plant_p = [], real_soc = [],sim_soc=[],sim_ess_p=[],sim_ess_plant_p=[]
            json.data.forEach((item) => {
                arr0.push(item.agc_demand)
                ess_p.push(item.ess_p)
                ess_plant_p.push(item.ess_plant_p)
                plant_p.push(item.plant_p)
                sim_soc.push(item.sim_soc)
                sim_ess_p.push(item.sim_ess_p)
                sim_ess_plant_p.push(item.sim_ess_plant_p)
                if (json.type == "line") {
                    time.push(new dayjs(item.start_time || item.time).format("YYYY-MM-DD HH:mm:ss"))
                } else {
                    time.push(new dayjs(item.start_time || item.time).format("YYYY-MM-DD"))
                }
                real_soc.push(item.real_soc)
            })

            tempSOC.xAxis.data = time
            tempSOC.series[0].data = sim_soc
            tempSOC.series[0].name = "优化后SOC"
            tempSOC.series[1]={
                data:real_soc,
                name:"优化前SOC",
                type:"line",
                showSymbol:false
            }

            temp.xAxis.data = time
            temp.series[0].name = "AGC指令"
            temp.series[0].data = arr0
            temp.series[0].type = json.type
            temp.series[0].step="start"

            temp.series[1] = {}
            temp.series[1].name = "储能出力"
            temp.series[1].data = ess_p
            temp.series[1].type = json.type
            temp.series[1].showSymbol = false

            temp.series[2] = {}
            temp.series[2].name = "联合出力"
            temp.series[2].data = ess_plant_p
            temp.series[2].type = json.type
            temp.series[2].showSymbol = false

            temp.series[3] = {}
            temp.series[3].name = "火电出力"
            temp.series[3].data = plant_p
            temp.series[3].type = json.type
            temp.series[3].showSymbol = false
            temp.series[4]={
                name:"（优化后）储能出力",
                data:sim_ess_p,
                type:json.type,
                showSymbol:false
            }
            temp.series[5]={
                name:"（优化后）联合出力",
                data:sim_ess_plant_p,
                type:json.type,
                showSymbol:false
            }
            if(type){
                let t=[]
                if(type=="优化前"){
                    t=["AGC指令","火电出力","储能出力","联合出力"]
                }else if(type=="优化后"){
                    t=["AGC指令","火电出力","（优化后）储能出力","（优化后）联合出力"]
                }
                temp.legend.data=t
                temp.series=temp.series.map((item)=>{
                    if(t.includes(item.name)){
                        return item
                    }else{
                        return {
                            ...item,
                            data:[]
                        }
                    }
                })
                tempSOC.series=tempSOC.series.map((item)=>{
                    if(item.name.includes(type)){
                        return {
                            ...item
                        }
                    }else{
                        return {
                            ...item,
                            data:[]
                        }
                    }
                })
                tempSOC.legend.data=tempSOC.legend.data.filter((item)=>item.includes(type))
            }
            setOptionArr(temp)
            setAgcOptionSOC(tempSOC)
        })
    }
    //日均kp
    const [aveKP, setAveKP] = useState(0)
    const [avePri, setAvePri] = useState(0)
    //优化前
    const [aveKPReal,setAveKPReal]=useState(0)
    const [avePriReal,setAvePriReal]=useState(0)
    //获取收益
    const getProfit = (type) => {
        customAxios({
            method: "get",
            url: "/profit/history",
            params: {
                start_time: timeRange[0].valueOf() ? timeRange[0].valueOf() : 1091473259792,
                end_time: timeRange[1].valueOf() ? timeRange[1].valueOf() : 1691473259792,
            }
        }).then((res) => {
            let json = res.data.data
            if (Array.isArray(json)) {
                let delta_q = [], profit = [], kp = [], x = [], k1 = [], k2 = [], k3 = [],real_profit=[],real_kp=[],real_k1=[],real_k2=[],real_k3=[],real_delta_q=[]
                json.forEach((item, key) => {
                    x.push(new dayjs(item.time).format("YYYY-MM-DD HH:mm:ss"))
                    delta_q.push(item.delta_q)
                    real_delta_q.push(item.real_delta_q)
                    profit.push(item.profit)
                    real_profit.push(item.real_profit)
                    real_k1.push(item.real_k1)
                    real_k2.push(item.real_k2)
                    real_k3.push(item.real_k3)
                    kp.push(item.kp)
                    real_kp.push(item.real_kp)
                    k1.push(item.k1)
                    k2.push(item.k2)
                    k3.push(item.k3)
                })
                let all = kp.reduce((s, i) => {
                    if(i>0){
                    return s + i
                    }else{
                        return s
                    }
                }, 0)
                let allReal=real_kp.reduce((s,i)=>{
                    if(i>0){
                    return s+i
                    }else{
                        return s
                    }
                },0)
                let allP = profit.reduce((s, i) => {
                    return s + i
                }, 0)
                let allPReal=real_profit.reduce((s,i)=>{
                    return s+i
                },0)
                setAvePriReal(Math.floor(allPReal))
                setAveKPReal(real_kp.length>0?parseFloat((allReal/real_kp.filter((item)=>item>0).length).toFixed(3)):0)
                setAvePri(Math.floor(allP))
                setAveKP(kp.length > 0 ? parseFloat((all / kp.filter((item)=>item>0).length).toFixed(3)) : 0)
                let tempProfit = JSON.parse(JSON.stringify({
                    ...AgcOption,
                    tooltip: {
                        ...AgcOption.tooltip,
                        valueFormatter: (value) => value + "元"
                    },
                    legend:{
                        data:["优化后收益","优化前收益"],
                        orient: "vertical",
                        right: 0
                    },
                    yAxis: {
                        type: 'value',
                        name: "元"
                    },
                }))
                let right = JSON.parse(JSON.stringify([
                    {
                        ...AgcOption,
                        yAxis: {
                            type: "value",
                            splitNumber: 4
                        },
                        legend:{
                            data:["优化后k1","优化前k1"],
                            orient: "vertical",
                            right: 0
                        },
                    },
                    {
                        ...AgcOption,
                        yAxis: {
                            type: "value",
                            splitNumber: 3
                        },
                        legend:{
                            data:["优化后k2","优化前k2"],
                            orient: "vertical",
                            right: 0
                        },
                    },
                    {
                        ...AgcOption,
                        yAxis: {
                            type: "value",
                            splitNumber: 3
                        },
                        legend:{
                            data:["优化后k3","优化前k3"],
                            orient: "vertical",
                            right: 0
                        },
                    },
                    {
                        ...AgcOption,
                        yAxis: {
                            type: "value",
                            splitNumber: 3
                        },
                        legend:{
                            data:["优化后kp","优化前kp"],
                            orient: "vertical",
                            right: 0
                        },
                    },
                    {
                        ...AgcOption,
                        tooltip: {
                            ...AgcOption.tooltip,
                            valueFormatter: (value) => value + "MW"
                        },
                        yAxis: {
                            type: 'value',
                            name: "MW",
                            splitNumber: 3
                        },
                        legend:{
                            data:["优化后里程","优化前里程"],
                            orient: "vertical",
                            right: 0
                        },
                    }
                ]))
                right.forEach((item) => {
                    item.xAxis.data = x
                })

                right[0].series[0].data = $.cloneDeep(k1)
                right[0].series[0].name = "优化后k1"
                right[0].series[1]={
                    data:real_k1,
                    name:"优化前k1",
                    type:"line",
                    showSymbol: false
                    
                }
                right[1].series[0].data = $.cloneDeep(k2)
                right[1].series[0].name = "优化后k2"
                right[1].series[1]={
                    data:real_k2,
                    name:"优化前k2",
                    type:"line",
                    showSymbol: false
                }


                right[1].yAxis.min = parseFloat(($.cloneDeep(k2).sort((a, b) => a - b)[0] * 0.99).toFixed(2))
                right[2].series[0].data = $.cloneDeep(k3)
                right[2].series[0].name = "优化后k3"
                right[2].series[1]={
                    data:real_k3,
                    name:"优化前k3",
                    type:"line",
                    showSymbol: false
                }

                right[2].yAxis.min = parseFloat(($.cloneDeep(k3).sort((a, b) => a - b)[0] * 0.99).toFixed(2))
                right[3].series[0].data = $.cloneDeep(kp)
                right[3].series[0].name = "优化后kp"
                right[3].series[1]={
                    data:real_kp,
                    name:"优化前kp",
                    type:"line",
                    showSymbol:false
                }
                right[3].yAxis.min = parseFloat(($.cloneDeep(kp).sort((a, b) => a - b)[0] * 0.98).toFixed(2))
                right[4].series[0].data = $.cloneDeep(delta_q)
                right[4].yAxis.min = parseFloat(($.cloneDeep(delta_q).sort((a, b) => a - b)[0] * 0.98).toFixed(2))
                right[4].series[0].name = "优化后里程"
                right[4].series[1]={
                    name:"优化前里程",
                    type:"line",
                    data:real_delta_q,
                    showSymbol:false
                }

                tempProfit.series[0].data = profit
                tempProfit.series[0].name = "优化后收益"
                tempProfit.series[1]={
                    data:real_profit,
                    name:"优化前收益",
                    type:"line",
                    showSymbol:false
                }
                tempProfit.xAxis.data = x
                if(type){
                    tempProfit.legend.data=tempProfit.legend.data.filter((item)=>item.includes(type))
                    tempProfit.series=tempProfit.series.map((item)=>{
                        if(!item.name.includes(type)){
                            return {...item,data:[]}
                        }else{
                            return item
                        }
                    })
                    right.forEach((arr)=>{
                        arr.legend.data=arr.legend.data.filter((item)=>item.includes(type))
                        arr.series=arr.series.map((i)=>{
                            if(!i.name.includes(type)){
                                return {
                                    ...i,
                                    data:[]
                                }
                            }else{
                                return {
                                    ...i
                                }

                            }
                        })
                    })
                }
                setAgcOptionProfit(tempProfit)
                setAgcOptionRight(right)
            }
        })
    }
    const adjustList = ["AGC指令响应", "收益曲线", "SOC变化曲线"]

    const rightlist = ["k1", "k2", "k3", "kp", "里程",]

    const plainOptions = ["优化前", "优化后"]
    const [checkedList,setCheckedList]=useState(["优化前","优化后"])

 
    useEffect(() => {
        if (timeRange) {
            if(checkedList=="优化前"||checkedList=="优化后"){
                getProfit(checkedList)
                getData(checkedList)
            }else if(checkedList.length>1){
                getProfit()
                getData()
            }else{
                getProfit("none")
                getData("none")
            }
        }
    }, [timeRange,checkedList])

    return <Layout>
        <div className="adjust-page">
            <div className="tit-bor">
                <div className="flex">
                    <div className="flex-1">
                        <div className="flex pos-rel">
                            <div className="flex-1">
                                <Checkbox.Group className="ml40" options={plainOptions} value={checkedList} onChange={(va)=>{setCheckedList(va)}}></Checkbox.Group>
                                <div
                                    className="fr"
                                // className="des-f-ad"
                                >
                                    <span style={{display:"none"}}>
                                        {/* 2023-06-12 06:00到2023-06-12 18:00范围内的 */}
                                        南网披露数据和优化后盘计算数据</span>
                                </div>
                            </div>
                            <div className="flex-480">
                                <Row className="tc">
                                    <Col span={12}>
                                        <div className='dr-shouyi'>
                                            <svg className='icon' aria-hidden="true" style={{ fontSize: "28px" }}>
                                                <use xlinkHref='#icon-shouyi'></use>
                                            </svg>
                                            <span className='font-nor'> 优化前收益 </span><span style={{ fontSize: "32px" }}>{avePriReal}</span> <span className='font-nor'>元</span>   </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className='current-kp'>
                                            <svg className='icon' aria-hidden="true" style={{ fontSize: "28px" }} >
                                                <use xlinkHref='#icon-jiankongzhibiao'></use>
                                            </svg>
                                            <span className='font-nor'> 优化前kp </span>  <span style={{ fontSize: "32px" }}>{aveKPReal}</span> </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </div>
                    <div className="flex-480">
                        <div className="flex">
                            <div className="flex-20">
                                <div className="divi-tit"><div className="line-h5 div-top">. .</div><div className="line-h5">. .</div><div className="line-h5">. .</div></div>
                            </div>
                            <div className="flex-1">
                                <Row className='tc'>
                                    <Col span={12} >
                                        <div className='dr-shouyi'>
                                            <svg className='icon' aria-hidden="true" style={{ fontSize: "28px" }}>
                                                <use xlinkHref='#icon-shouyi'></use>
                                            </svg>
                                            <span className='font-nor'> 优化后收益 </span><span style={{ fontSize: "32px" }}>{avePri}</span> <span className='font-nor'>元</span>   </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className='current-kp'>
                                            <svg className='icon' aria-hidden="true" style={{ fontSize: "28px" }} >
                                                <use xlinkHref='#icon-jiankongzhibiao'></use>
                                            </svg>
                                            <span className='font-nor'> 优化后Kp </span>  <span style={{ fontSize: "32px" }}>{aveKP}</span> </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col span={4} className="pl40">
                    </Col>
                    <Col className="tr" span={14}>
                    </Col>
                    <Col span={6}>
                    </Col>
                </Row>
            </div>
            <div className="flex line-mag">
                <div className="flex-1">
                    <Card>
                        {
                            adjustList.map((item, key) => {
                                return <div key={key} className="mb20">
                                    <span className="des-title-echa">{item}</span>
                                    {item == "收益曲线" && <div><ReactECharts style={{ height: "200px" }} option={AgcOptionProfit} /></div>}
                                    {item == "AGC指令响应" && <div ><ReactECharts style={{ height: "200px" }} option={optionArr} /></div>}
                                    {item == "SOC变化曲线" && <ReactECharts style={{ height: "200px" }} option={AgcOptionSOC} />}
                                </div>
                            })
                        }
                    </Card>
                </div>
                <div className="flex-480">
                    <Card className="ml20" >
                        {
                            rightlist.map((item, key) => {
                                return <div key={key}>
                                    {item}
                                    <ReactECharts style={{ height: "125px" }} option={AgcOptionRight[key]} />
                                </div>
                            })
                        }
                    </Card>
                </div>
            </div>
            <div className="date-fixed">
                <Row>
                    <Col span={24}>
                        <DatePicker callback={settTimeRange} ></DatePicker>
                    </Col>
                </Row>
            </div>
        </div>
    </Layout>
}