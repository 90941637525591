import Layout from "../../component/LayoutCom";
import Header from '../../component/HeaderTab'
import { Button, Card, Col, Row, Table, DatePicker, Divider, Input } from "antd";
import EChartsReact from "echarts-for-react";
import { useGetQuery } from '../../customEffect'


import './index.scss'
import { useState } from "react";

const { RangePicker } = DatePicker;

//数据操作
export default function View() {
    const query = useGetQuery()
    const detailList = ["调频曲线", "中标时段", "报价"]
    const AgcOption = {
        grid: {
            top: 10,
            bottom: 40
        },
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [150, 230, 224, 218, 135, 147, 260],
                type: 'line'
            }
        ]
    }
    //回调获取数据
    const getInputData=(arr)=>{
        console.log(arr,"arr")
    }
    return <Layout>
        <div className="page-data">
            {
                query.tab == 2 ? <PageDownload></PageDownload> : <div>
                    <Card>
                        <DataUp callback={getInputData}></DataUp>
                    </Card>
                    <Card className="mt20">
                        <div className="title">详情</div>
                        <Row>
                            {
                                detailList.map((item, key) => {
                                    return <Col span={8} key={key}>
                                        {item}
                                        <EChartsReact option={AgcOption}></EChartsReact>
                                    </Col>
                                })
                            }
                        </Row>
                    </Card>
                    <Card className="mt20">
                        <UploadHistory></UploadHistory>
                    </Card>
                </div>
            }
        </div>
    </Layout>
}

//数据上传
function DataUp(props) {
    const {callback}=props
    const arr = [...new Array(24).keys()]
    const valueArr=[
        new Array(24).fill(0),
        new Array(24).fill(0),
        new Array(24).fill(0)
    ]
    const [tableValue,setTableValue]=useState(valueArr)
    const inputChange=(fir,sec,value)=>{
        const arr=[...tableValue]
        arr[fir][sec]=value
        setTableValue(arr)
        callback &&callback(arr)
    }
    return <div className="up-table">
        <table>
            <thead>
                <tr>
                <th></th>
                {arr.map((item, key) => {
                    return <th key={key}>{item + 1}:00</th>
                })}
                </tr>
            </thead>
            <tbody>
                {
                    ["调频", "价格", "中标"].map((item, key) => {
                        return <tr key={key}>
                            <td className="tab-li">{item}</td>
                            {arr.map((i, j) => {
                                return <td key={j}>
                                    <Input onChange={(e)=>{inputChange(key,j, e.target.value)}} value={tableValue[key][j]}></Input>
                                </td>
                            })}
                        </tr>
                    })
                }
            </tbody>
        </table>
        <Button type="primary" className="fr mt20">数据上传</Button>
    </div>
}

function PageDownload() {
    return <div className="mt20">
        <Card>
            <div className="title">数据下载</div>
            <Row>
                <Col span={3}></Col>
                <Col span={18} className="">
                <div>
                <div className="tl export-title">
                    云平台数据导出
                </div>
                <div className="time-align">
                    <div> <span>时间：</span>    <RangePicker></RangePicker> <Button type="primary" className="ml20">下载</Button></div>
                    <div className="des">默认导出全量数据，您也可以设置时间范围进行下载。</div>
               
                </div>
            </div>
            <div className="mt20">
                <div className="tl export-title">
                    亚控数据库下载
                </div>
                <div className="time-align">
                <span>时间：</span>    <RangePicker></RangePicker> <Button type="primary" className="ml20">下载</Button>
                </div>
            </div>
                </Col>
                <Col span={3}></Col>
            </Row>
        </Card>
        <div className="pb20">
        <Card className="mt20">
            <DownloadHistory></DownloadHistory>
        </Card>
        </div>
    </div>
}

function DownloadHistory() {
    const dataSource = [
        {
            key: '1',
            time: '2012-12-09',
            age: 32,
            address: '西湖区湖底公园1号',
        },
        {
            key: '2',
            time: '2012-12-09',
            age: 42,
            address: '西湖区湖底公园1号',
        },
    ];

    const columns = [
        {
            title: '下载时间',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: '数据时间范围',
            dataIndex: 'range',
            key: 'range',
        },
        {
            title: '数据类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '操作人',
            dataIndex: 'operater',
            key: 'operater',
        }
    ];
    return <div>
        <div className="title">下载记录</div>
        <Table dataSource={dataSource} columns={columns} />
    </div>
}

function UploadHistory() {
    const dataSource = [
        {
            key: '1',
            time: '2012-12-09',
            age: 32,
            address: '西湖区湖底公园1号',
        },
        {
            key: '2',
            time: '2012-12-09',
            age: 42,
            address: '西湖区湖底公园1号',
        },
    ];

    const columns = [
        {
            title: '上传时间',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: '详情',
            dataIndex: 'detail',
            key: 'detail',
        },
        {
            title: '操作人',
            dataIndex: 'operate',
            key: 'operate',
        },
        {
            title: '操作',
            dataIndex: 'op',
            key: 'op',
        }
    ];
    return <div>
        <div className="title">
            上传记录
        </div>
        <Table dataSource={dataSource} columns={columns} />
    </div>
}