import './index.scss'
//电池组件
export default function View(props){
    const {soc,soh,name}=props
    const unitWidth=10
    const getBlock=(value)=>{
        //返回值，【n,c】
        if(value){
            let n=Math.trunc(value*100/10) 
            let c=Math.floor(value*100%10)
            return [n,c] 
        }else{
            return [0,0]
        }
    }
    const renderObj=getBlock(soc)
    
    return <div className="battery-cell">
        <div className='bat-name'>
            <div></div>
            <div>U{name}</div>
            </div>
        <div className='batt-border' >
            <div  className="batt-soc"> 
            {
                [...new Array(renderObj[0]).keys()].map((item)=>{
                    return <Unit key={item}></Unit>
                }) 
            }
            {
                !!renderObj[1]&&<Unit width={renderObj[1]}></Unit>
            }
            <div className='soc-flo'>  SOC:{ (soc*100).toFixed(0)}%</div>
            </div>
            {/* <div style={{width:`${soh*100}%`}} className='batt-soh' > soh:{soh}</div> */}
        </div>
    </div>
}

function Unit(props){
    const {width=0}=props
    const w=width?width*2+'px':"20px"
    return <div className='unit-ceil' style={{width:w}}>

    </div>
}