import { Row, Col, Modal, Switch, Card, Input, Button, Space, Empty } from "antd";
import { useEffect, useState } from "react";
import ReactECharts from 'echarts-for-react';
import dayjs from "dayjs";


import Layout from "../../component/LayoutCom";
import BatteryDetail from '../../component/BatteryDetail'
import BatteryCluster from '../../component/BatteryCluster'

import './index.scss'
import { customAxios } from "../../utils";

export default function View() {
    const cluster = new Array(6).fill({ soc: 0.1 })
    const battery = new Array(24).fill({
        data: cluster
    })
    const [batteryState, setBatteryState] = useState(battery)
    const [newState,setNewState]=useState([])
    console.log(batteryState,newState,"batteryState")
    const getData = () => {
        customAxios({
            method: "get",
            url: "/bcms/now"
        }).then((res) => {
            let json = res.data
            let temp = JSON.parse(JSON.stringify(battery))
            let arr = [[], [],[],[],[],[]]
            if (Array.isArray(json)) {
                json.forEach((item) => {
                    let pos = (parseFloat(item.tags.bams) - 1) * 6 + (parseFloat(item.tags.bbms) - 1)
                    temp[pos].name = item.tags.bams + "-" + item.tags.bbms
                    temp[pos].data[parseFloat(item.tags.bcms) - 1] = {
                        ...item,
                        soc: item.soc / 100,
                    }
                })
            }
            //没有数据的，给电池簇命名
            let count = 0
            for (let i = 1; i < 7; i++) {
                for (let j = 1; j < 5; j++) {
                    temp[count].name = i + "-" + j
                    count++
                }
            }
            temp.forEach((item)=>{
                let index=parseInt(item.name.split("-")[0])-1 
                arr[index].push(item)
            })
             //mock 剩下的22组
            if(!arr[0][2].data[0].time){
                let templeData=arr[0][1].data
                arr.forEach((a,key)=>{
                    a.forEach((i,j)=>{
                        if(!i.data[0].time){
                            i.data=templeData
                        }
                    })
                })

            }
            console.log(arr,"new Arr")
            setNewState(arr)
            setBatteryState(temp)
        })
    }
    useEffect(() => {
        getData()
    }, [])
    const [detailModal, setDetailModal] = useState(false)
    const [swit, setSwit] = useState(false)
    const alarmList = [{ name: "电池簇1", content: "电压过小" }]
    const checkListInit = [
        // {
        //     time: "2012-05-13",
        //     itemList: ["U2-1"]
        // }
    ]
    const [checkList, setCheckList] = useState(checkListInit)
    useEffect(()=>{
        getList({})
        getAlarm()
    },[])

    const [errList,setErrList]=useState([])
    //获取电池预警列表
    const getAlarm=()=>{
        customAxios({
            method:"get",
            url:"/bbms/now"
        }).then((res)=>{
            let json=res.data
            let err=[]
            if(Array.isArray(json)){
                json.forEach((item)=>{
                    if(item.abnormal.code!=0){
                        err.push(item)
                    }
                })
                console.log(err,"err")
                setErrList(err)
            }
        })
    } 
    //查询
    const getList = (params) => {
        customAxios({
            method: "get",
            url: "/maintenances",
            params: {
                ...params,
                state: 0,
                type: "电池",
                pagesize: 10000
            }
        }).then((res) => {
            let json = res.data.data
            if (Array.isArray(json)) {
                let arr = json.map((item, key) => {
                    return {
                        ...item,
                        itemList: item.devices,
                        time: item.started_at ? new dayjs(item.started_at).format("YYYY-MM-DD HH:mm:ss") : undefined
                    }
                })
                setCheckList(arr)

            }
            console.log(json, "json")
        })
    }

    const option = {
        grid: {
            top: 10,
            bottom: 40
        },
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [150, 230, 224, 218, 135, 147, 260],
                type: 'line'
            }
        ]
    }
    const [id, setId] = useState(0)
    return <Layout>
        <Modal
            destroyOnClose={true}
            forceRender={true}
            width={1024}
            maskClosable={false} open={detailModal} footer={null} onCancel={() => { setDetailModal(false) }}>
            <BatteryDetail id={id} ></BatteryDetail>

        </Modal>
        <div className="battery-page">
            <div className="tit-bor">
                <div className="flex">
                    <div className="flex-1">
                        <Row>
                            <Col span={12} className='tl'><div className="switch-head">
                                <span>BMS采集 </span>  <Switch checked={swit} onChange={() => { setSwit(!swit) }}></Switch><span> AI评估(SOH)</span>
                            </div></Col>
                            <Col span={12} className='tr'>
                                <Space style={{display:"none"}}>
                                    <span>电池预警温度</span>
                                    <Input defaultValue={45} ></Input>
                                    <Button type="primary">修改</Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                    <div className="flex-400"></div>
                </div>
            </div>
            <div className="flex">
                <div className="flex-1 mr20">
                    <Card className="mb20 ">
                    {
                            newState.length>0&&    newState.map((item,key)=>{
                                    return <Card key={key} className="mb20 card-border">
                                        <Row>
                                            {item.map((f,fkey)=>{
                                                return <Col span={12} key={fkey} className={`${fkey==0||fkey==1?"line-divide":""} ${fkey==0||fkey==2?"clu-border":""} p10`}>
                                                     <div className={`${errList.map((item)=>{
                                                return item.tags.bams+"-"+item.tags.bbms
                                            }).includes(item.name)?"tc warn-unit":"tc"}`}>U{f.name}</div>
                                                    <Row>
                                                    {
                                                        f.data.map((i,j)=>{
                                                            return <Col span={4}  key={j} onClick={() => {
                                                                let cell = j + 1
                                                                setId(f.name + "-" + cell)
                                                                setDetailModal(true)
                                                            }}>
                                                                <BatteryCluster soc={i.soc}></BatteryCluster>
                                                                <div style={{fontSize:"8px",color:"green",textAlign:"center"}}>SOH:{swit?i.soh_adjusted&&i.soh_adjusted.toFixed(0):i.soh&&i.soh.toFixed(0)}%</div>
                                                                <div className="num-clus">{j + 1}</div>
                                                            </Col>
                                                        })
                                                    }
                                                    </Row>
                                                </Col>
                                            })}
                                        </Row>

                                    </Card>

                                })
                            }
                        <Row >
                           
                            {
                               false&& batteryState.map((item, key) => {
                                    return <Col key={key} span={12} className={`${key % 2 == 0 ? "clu-border  line-divide " : "line-divide"}`}>
                                        <div className="bor-s-clu">
                                            <div className={`${errList.map((item)=>{
                                                return item.tags.bams+"-"+item.tags.bbms
                                            }).includes(item.name)?"tc warn-unit":"tc"}`}>U{item.name}</div>
                                            <Row>
                                                {
                                                    item.data.map((i, j) => {
                                                        return <Col span={4} key={j} onClick={() => {
                                                            let cell = j + 1
                                                            setId(item.name + "-" + cell)
                                                            setDetailModal(true)
                                                        }}>
                                                            <BatteryCluster soc={i.soc}></BatteryCluster>
                                                            <div style={{fontSize:"8px",color:"green"}}>SOH:{swit?i.soh_adjusted&&i.soh_adjusted.toFixed(0):i.soh&&i.soh.toFixed(0)}%</div>
                                                            <div className="num-clus">{j + 1}</div>
                                                        </Col>
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </Col>
                                })
                            }
                        </Row>
                    </Card>
                </div>
                <div className="flex-400">
                    <Card>
                        <div className="ca-transf">
                            <div>
                                <div className="alarm-list com-list-title" >
                                    <svg className='icon' aria-hidden="true" >
                                        <use xlinkHref="#icon-yujing"></use>
                                    </svg>
                                    <span> 故障预警列表</span></div>
                                <div className="content-detail">
                                    {
                                        errList.length>0?<div style={{overflow:"auto",height:"100%",color:"red"}}>
                                            {
                                                errList.map((item,key)=>{
                                                    return <div key={key}>U{item.tags.bams}-{item.tags.bbms}:{item.abnormal.message}</div>
                                                })
                                            }
                                        </div>: <Empty></Empty>
                                    }
                                   
                                    {/* {
                                        alarmList.map((item, key) => {
                                            return <div key={key}>{item.name}:{item.content}</div>
                                        })
                                    } */}
                                </div>
                            </div>
                            <div className="check-time  com-list-title ">
                                <svg className='icon' aria-hidden="true" >
                                    <use xlinkHref="#icon-jianxiutai"></use>
                                </svg>
                                <span> 检修时间提醒</span> </div>
                            <div className="content-detail">
                                {
                                    checkList.length > 0 ? <div style={{height:"150px",overflow:"auto"}}>
                                        {
                                            checkList.map((item, key) => {
                                                return <div key={key}>
                                                    {
                                                        item.time
                                                    }<span style={{color:"#666"}}> 建议检修 </span>
                                                    
                                                    <span style={{fontSize:"16px"}}>
                                                    {
                                                        item.itemList.join(",")
                                                    }
                                                    </span>
                                                </div>
                                            })
                                        }
                                    </div> : <Empty></Empty>
                                }
                            </div>
                            <div className="sim-case com-list-title">
                                <svg className='icon' aria-hidden="true" >
                                    <use xlinkHref="#icon-dianchi"></use>
                                </svg>
                                <span> 图例</span>
                            </div>
                            <div className="content-detail">
                                <div>
                                    <BatteryCluster width="300"></BatteryCluster>
                                </div>
                                <Row>
                                    <Col span={12}>
                                        颜色表示电池簇状态
                                    </Col>
                                    <Col span={12} className="mt10">
                                        {
                                            [{ name: "健康" }, { name: "故障预警" }, { name: "真实故障" }].map((item, key) => {
                                                return <div key={key}>
                                                    <Space>
                                                        <div className={`block-com${key} same-bloc`}></div>
                                                        <span>{item.name}</span>
                                                    </Space>
                                                </div>
                                            })
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    </Layout>
}