
import  Header from '../HeaderTab'

export default function View(props){

    
    return <div style={{padding:"0 20px"}}>
        <Header></Header>
        {
            props.children
        }
    </div>
}