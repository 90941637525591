import { Slider, Space, DatePicker } from "antd"
import { useEffect, useRef, useState } from "react"
import dayjs from 'dayjs'
import './index.scss'
import $ from 'lodash'

const { RangePicker } = DatePicker
export default function View(props) {
    const { callback } = props
    const [min, setMin] = useState(10)
    const [max, setMax] = useState(100)
    const [slideValue, setSlideValue] = useState([])

    //上次移动的位置
    const lastPos = useRef(null)
    useEffect(() => {
        //初始化日期
        let base = new dayjs(new dayjs("2023-06-18").format("YYYY-MM-DD"))
        let start = base.valueOf()
        let end = base.add(1, "day").add(-1, "second").valueOf()
        let arr = [start, end]
        let maxT = (end - start) / 2 + end
        let minT = start - (end - start) / 2
        setMin(minT < 0 ? 0 : Math.floor(minT))
        setMax(Math.ceil(maxT))
        setSlideValue(arr)
        lastPos.current = arr
    }, [])
    //一天的毫秒总数
    const onDay = 1000 * 60 * 60 * 24
    const slideChange = (value) => {
        if (!(lastPos.current && (lastPos.current[0] == value[1] || lastPos.current[1] == value[0]))) {
            //左移动，临近左边界
            if (value[0] < min + 2) {
                let minT = value[0]  - onDay
                setMin(Math.floor(minT))
            }
            //右移，临近右边界
            if (value[1] > max - 2) {
                let maxT = value[1] + onDay
                setMax(Math.ceil(maxT))
            }
            setSlideValue(value)
        }
    }
    const [marks, setMarks] = useState({})
    const onAfterChange = (value) => {
        lastPos.current = value
        // callback && callback(value)
        //右节点左移动，超过中点
        //左节点右移动，超过中点
        if ((value[1] < (max - min) / 2 + min) || (value[0] > (max - min) / 2 + min)) {
            let maxT = (value[1] - value[0]) / 2 + value[1]
            let minT = value[0] - (value[1] - value[0]) / 2
            setMin(minT < 0 ? 0 : Math.floor(minT))
            setMax(Math.ceil(maxT))
        }
    }
    const [dateShow,setDateShow]=useState([])
    useEffect(() => {
        let obj = {}
        let a = [ ...slideValue]
        a.forEach((item) => {
            obj[item] = new dayjs(item).format("YYYY-MM-DD HH:mm:ss")
        })
        setMarks(obj)
        let temp=[]
        slideValue.forEach((item,key)=>{
            temp[key]=new dayjs(item)
        })
        setDateShow(temp)
    }, [slideValue])
    const throttled=useRef($.throttle((time)=>{
        callback && callback(time)
    },1000))
    useEffect(()=>{
        if(dateShow.length>0){
            throttled.current(dateShow)
            // callback && callback(dateShow)
        }
    },[dateShow])


    const rangeChange=(value)=>{
        let arr=value.map((item)=>{
            return item.valueOf()
        })
        // setSlideValue(arr)
        slideChange(arr)
        onAfterChange(arr)
    }

    return <div className="com-datepic">
        {/* datePick
        <div>min:{min}</div>
        <div>max:{max}</div>
        <div>value:{slideValue.toString()}</div> */}
        <Space><span>手动选择时间</span>
            <RangePicker
            onChange={(value)=>{rangeChange(value)}}
            value={dateShow}
                showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('11:59:59', 'HH:mm:ss')],
                }}
            ></RangePicker></Space>
        <div className="slider-con">
            <div className="flex-width">
                <div>
                {new dayjs(min).format("YYYY-MM-DD")}
                </div>
                <div>
                {new dayjs(min).format("HH:mm:ss")}
                </div>
            </div>
            <div className="flex-1">
                <Slider
                    range={{ draggableTrack: true }}
                    marks={marks}
                    tooltip={{
                        formatter: (value) => {
                            return <div>{new dayjs(value).format("YYYY-MM-DD HH:mm:ss")}</div>
                        }
                    }
                    }
                    min={min} max={max} value={slideValue} onChange={(value) => {
                        slideChange(value)
                    }}
                    onAfterChange={onAfterChange}
                >
                </Slider>
            </div>
            <div className="flex-width">
                <div>
                {new dayjs(max).format("YYYY-MM-DD")}
                </div>
                <div>
                {new dayjs(max).format("HH:mm:ss")}
                </div>
               
            </div>
        </div>

    </div>
}