//电池簇
import './index.scss'
export default function View(props){
    const {width,soc=-1}=props
    // const soc=0.5
    const soh=0.2
    let styleObj={}
    if(soc==-1){
        styleObj={lineHeight:"48px"}
    }
    return <div className='com-bat-cluster'>
        <div className='content-c' style={width?{width:width+"px",height:"48px"}:{}}>
        <div className='soc' >
            <div className='ab-font' style={styleObj} >SOC:{soc==-1?"88":parseFloat((soc*100).toFixed(0))}%</div>
            <div className='soc-con' style={{width:`${soc*100}%`}}></div>
            </div>
        {/* <div className='soh'>soh:{soh}</div> */}
        </div>
    </div>
}