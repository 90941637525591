import {useEffect, useState} from 'react'
function useSetTitle(name){
    const [title,setTitle]=useState(name)
    useEffect(()=>{
        document.title=title
    },[])
    // return title
}

//获取pathname
function useGetPathname(){
    let pathname=""
    if(window.location){
        pathname=window.location.pathname.slice(1)
    }
    return pathname
}


//获取url 查询条件
function useGetQuery(){
    let obj={}
    if(window.location){
        let search=window.location.search
        if(search){
            search.slice(1).split("&").forEach((item)=>{
                let arr=item.split("=")
                obj[arr[0]]=arr[1]
            })
        }
    }
    return obj
}

export  {
    useSetTitle,
    useGetQuery,
    useGetPathname
}