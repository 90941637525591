import Layout from "../../component/LayoutCom";
import Header from '../../component/HeaderTab'
import { Button, Card, Divider, Modal, Table, Descriptions, Timeline } from 'antd';

import { useNavigate } from 'react-router-dom'
import {
    RollbackOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons';


import './index.scss'
import { useGetQuery } from "../../customEffect";
import { useEffect, useRef, useState } from "react";
import {customAxios} from '../../utils'
import dayjs from "dayjs";

//故障详情
export default function View() {
    const router = useNavigate()
    const query=useGetQuery()
    console.log(query)
    const [attachments,setAttachments]=useState([])
    
    const getDetail=()=>{
        customAxios({
            method: "get",
            url: `/faults/${query.id}`
        }).then((res) => {
            let json = res.data
            console.log(json)
            const { description, device, operation, operator = [], state, type, fault_time, tags=[],recover_time,history=[],attachments=[],updated_at } = json
            setAttachments(attachments)
            let arr=[]
            if(history.length>0){
                 arr=history.map((item)=>{
                    return {
                        ...item,
                        time:new dayjs(item.updated_at).format("YYYY-MM-DD HH:mm:ss") ,
                        content:item.operation
                    }
                })
            }
            arr.push({
                state:state,
                operator:operator,
                time:new dayjs(updated_at).format("YYYY-MM-DD HH:mm:ss")
            })
            setTransListState(arr)
            let temp=[{
                lab:"故障发生时间",
                des:new dayjs(fault_time).format("YYYY-MM-DD HH:mm:ss")
            },
            {
                lab:"恢复时间",
                des:new dayjs(recover_time).format("YYYY-MM-DD HH:mm:ss")
            },
            {
                lab:"设备编号",
                des:device
            },
           
            {
                lab:"故障类型",
                des:type
            },
            {
                lab:"故障标签",
                des:tags.join(",")
            },
            {
                lab:"状态：",
                des:state==0?"未处理":"已处理"
            },
            {
                lab:"操作人",
                des:operator.join(","),
                span:2
            },
            {
                lab:"故障描述",
                des:description,
                span:4
            }
            ,
            {
                lab:"处理详情",
                des:operation,
                span:4
            },
           ]
            
            setDesArrState(temp)
        })

    }
    const onMount=useRef(null)
    useEffect(()=>{
        if(query.id&&!onMount.current){
            getDetail()
            onMount.current=true
        }
    },[query])

    const desArr = [{ lab: "故障发生时间：", des: "2023-06-12" }, {
        lab: "设备编号", des: "U2-23"
    }]
    const [desArrState,setDesArrState]=useState(desArr)
    const transList = [{
        time: "2012-12-12",
        content: "创建"
    }, {
        time: "2023-12-21",
        content: "设置成已完成"
    }]
    const [transListState,setTransListState]=useState([])
    const downloadFile = (arr) => {
        if (arr.length !== 2) return
        let origin = "https://qiaokou.testing.tsingroc.com/api"
        if (process.env.REACT_APP_ORIGIN) {
            origin = process.env.REACT_APP_ORIGIN
        }
        return origin + `/file/${arr[1]}?filename=${arr[1]}&id=${arr[0]}`
    }
    return <Layout>
        <div className="page-lab-detail">
            <Card className="mt20">
                <div className="flex">
                    <div className="flex-30 pt5">
                    <ArrowLeftOutlined style={{fontSize:"18px"}} onClick={() => { router(-1) }} />
                    </div>
                    <div className="flex-1">
                    <div className="acc-det-titl">故障详情</div>
                    <Divider></Divider>
                    <Descriptions column={4}>
                    {
                        desArrState.map((item, key) => {
                            return <Descriptions.Item span={item.span?item.span:1} label={item.lab}>{item.des}</Descriptions.Item>
                        })
                    }
                </Descriptions>
                {attachments.length>0&&<div>
                    <div>附件：</div>
                    <div className="ml20">
                    {
                        attachments.map((item,key)=>{
                            return <div key={key}><a href={downloadFile(item.split("|"))}>{item.split("|")[1]}</a></div>
                        })
                    }
                    </div>
                    </div>}
                <Divider></Divider>
                <div className="mb20 fw500">流转记录</div>
                <Timeline
                    items={
                        transListState.map((item,key) => {
                            let o=""
                            if(item.operator){
                                o=item.operator.join(",")
                            }
                            return {
                                children: item.time +" "+ o+" " + opMap[item.state],
                            }
                        })
                    }
                />
                    </div>
                </div>
            </Card>
        </div>
    </Layout>
}
const opMap=["未处理","已处理"]