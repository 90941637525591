import { Col, Divider, Space, Row, Card, Empty, Tag } from "antd"
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from "react";
import DatePicker from '../DatePicker'
import './index.scss'
import { customAxios } from "../../utils";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom'


//电池详情
export default function View(props) {
    const { id: batteryId } = props
    const [valueArr, setValueArr] = useState([])
    const getData = () => {
        if (batteryId) {
            let arr = batteryId.split("-")
            //mock 特殊处理，按U1-2处理
            if(arr[0]!=1||(arr[0]==1&&arr[1]>2)){
                arr=[1,2,arr[2]]
            }
            customAxios({
                method: "get",
                url: "/bcms/now",
                params: {
                    bams: arr[0],
                    bbms: arr[1],
                    bcms: arr[2]
                }
            }).then((res) => {
                let json = res.data[0]
                const { u, i, p, soh, soc, t_cell_max, t_cell_min, soe } = json
                let arr = [u, i, p, soh, soc, t_cell_max, t_cell_min, soe].map((item) => { return parseFloat(item.toFixed(2)) })
                setValueArr(arr)
            })
        }
    }
    const AgcOption = {
        grid: {
            top: 30,
            bottom: 40,
            left: "20%"
        },
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value',
            name: "%"
        },
        series: [
            {
                data: [],
                type: 'line'
            }
        ],
        tooltip: {
            show: true,
            trigger: "axis"
        },
        dataZoom: {
            type: "slider"
        }
    }
    const [optionState, setOptionState] = useState([{
        ...AgcOption,
        tooltip: {
            ...AgcOption.tooltip,
            valueFormatter: (value) => value + "V",
            // trigger: 'item',  //此时不要为'axis',否则params.name或者params.value不显示
            formatter: function (p) {
                let  params=p[0]
                if(Array.isArray(params.value) ){
                    return  params.name + '<br>' +
                    '开始:' + params.value[1] +"V"+ '<br>' +
                    '结束:' + params.value[2] +"V"+ '<br>' +
                    '最低:' + params.value[3] +"V"+ '<br>' +
                    '最高:' + params.value[4]+"V"
                }else{
                    return  params.name + '<br>' +
                    '电压:' + params.value +"V"+ '<br>' 
                }
               
            }
        },
        yAxis: {
            type: "value",
            min: 500,
            name: "V"
        }
    }, {
        ...AgcOption,
        tooltip: {
            ...AgcOption.tooltip,
            valueFormatter: (value) => value + "A"
        },
        yAxis: {
            type: "value",
            name: "A"
        }
    }, {
        ...AgcOption,
        tooltip: {
            ...AgcOption.tooltip,
            valueFormatter: (value) => value + "kW"
        },
        yAxis: {
            type: "value",
            name: "kW"
        }
    }, {
        ...AgcOption,
        tooltip: {
            ...AgcOption.tooltip,
            valueFormatter: (value) => value + "%"
        },
    }, {
        ...AgcOption,
        tooltip: {
            ...AgcOption.tooltip,
            valueFormatter: (value) => value + "%",
            formatter: function (p) {
                let  params=p[0]
                if(Array.isArray(params.value) ){
                    return  params.name + '<br>' +
                    '开始:' + params.value[1] +"%"+ '<br>' +
                    '结束:' + params.value[2] +"%"+ '<br>' +
                    '最低:' + params.value[3] +"%"+ '<br>' +
                    '最高:' + params.value[4]+"%"
                }else{
                    return  params.name + '<br>' +
                    'SOC:' + params.value +"%"+ '<br>' 
                }
            }
        },
    }, {
        ...AgcOption,
        tooltip: {
            ...AgcOption.tooltip,
            valueFormatter: (value) => value + "摄氏度"
        },
        legend: {
            data: ["最大温度", "最小温度"],
            right: 0
        },
        yAxis: {
            type: "value",
            name: "摄氏度"
        }
    }])
    const [time, setTime] = useState([])
    const oneDay=86400000
    const getHistoryData = () => {
        if (batteryId && time.length > 0) {
            let dix=time[1].valueOf()-time[0].valueOf()
            let arr = batteryId.split("-")
            //mock数据特殊处理
            if(arr[0]!=1||(arr[0]==1&&arr[1]>2)){
                arr=[1,2,arr[2]]
            }
            customAxios({
                method: "get",
                url: "/bcms/history",
                params: {
                    bams: arr[0],
                    bbms: arr[1],
                    bcms: arr[2],
                    start_time: time[0].valueOf(),
                    end_time: time[1].valueOf(),
                    // start_time:1686787200000,
                    // end_time:1687046400000,
                    resp_type: "candlestick",
                    interval: dix>oneDay-10000?"1h":dix>oneDay/4?"30m":dix>oneDay/12?"10m":"1m"
                }
            }).then((res) => {
                let json = res.data
                if (Array.isArray(json.data)) {
                    let type = json.type
                    //小于12h，换折线图
                    if (time[1].valueOf() - time[0].valueOf() < 43199500) {
                        type = "line"
                        console.log(time[1].valueOf() - time[0].valueOf())
                    }
                    let temp = JSON.parse(JSON.stringify(optionState))
                    let timeX = [], t_cell_max = [], t_cell_min = [], i = [], p = [], soc = [], soh = [], u = []
                    json.data.forEach((item, index) => {
                        if (index == 0) {
                            timeX.push(new dayjs(item.start_time).format("MM/DD HH:mm:ss"))
                        } else {
                            timeX.push(new dayjs(item.start_time).format("HH:mm:ss"))
                        }
                        t_cell_max.push(item.t_cell_max)
                        t_cell_min.push(item.t_cell_min)
                        i.push(item.i)
                        p.push(item.p)
                        soc.push(item.soc)
                        soh.push(item.soh)
                        u.push(item.u)
                    })
                    temp[0].series[0] = {
                        name: "电压",
                        data: type == "line" ? u.map((item) => item[0]) : u,
                        type: type,
                        symbol: "none",
                        smooth: !true
                    }
                    temp[3].series[0] = {
                        name: "SOH",
                        data: soh.map((item) => {
                            return item[0]
                        }),
                        type: "line",
                        symbol: 'none',

                    }
                    temp[4].series[0] = {
                        name: "soc",
                        data: type == "line" ? soc.map((item) => item[0]) : soc,
                        type: type,
                        symbol: "none",
                        smooth: !true
                    }
                    temp[2].series[0] = {
                        name: "功率",
                        data: i.map((item) => {
                            return item[0]
                        }),
                        type: "line",
                        symbol: 'none',
                        smooth: !true
                    }
                    temp[5].series[0] = {
                        name: "最大温度",
                        data: t_cell_max.map((item) => { return item[0] }),
                        type: "line",
                        symbol: "none",
                        smooth: !true
                    }
                    temp[5].series[1] = {
                        name: "最小温度",
                        data: t_cell_min.map((item) => { return item[0] }),
                        type: "line",
                        symbol: "none",
                        smooth: !true
                    }
                    temp[1].series[0] = {
                        data: i.map((item) => {
                            return item[0]
                        }
                        ),
                        name: "电流",
                        type: "line",
                        symbol: 'none',
                        smooth: !true
                    }
                    temp.forEach((item) => {
                        item.xAxis.data = timeX
                    })
                    setOptionState(temp)
                }
                console.log(json)
            })
        }
    }
    const [dataSource,setDataSource]=useState([])
    //获取电池历史故障
    const getlist = (params) => {
        if(batteryId){
            let arr = batteryId.split("-")
        customAxios(
            {
                method: "get",
                url: "/faults",
                params: {
                    ...params,
                    device: "电池-组"+arr[0]+"-单元"+arr[1]+"-簇"+arr[2],
                    desc: true,
                    sort_by: "fault_time",
                    pagesize: 100000
                }
            }
        ).then((res) => {
            let json = res.data.data
            if (Array.isArray(json)) {
                let arr = json.map((item, key) => {
                    return {
                        ...item,
                        key: (key + 1).toString(),
                        operater: item.operator && item.operator.join(","),
                        time: new dayjs(item.fault_time).format("YYYY-MM-DD HH:mm:ss"),
                        detail: item.description,
                        num: item.device,
                        dealDetail: item.operation

                    }
                })
                setDataSource(arr)
            }else{
                setDataSource([])
            }
        })
        }
    }
    useEffect(() => {
        if (time.length > 0) {
            getData()
            getHistoryData()
            getlist()
        }
    }, [batteryId, time])
    //时间选择组件回调
    const callback = (value) => {
        setTime(value)
        console.log("DatePicker callback", value)
    }
    const unit = [
        "V", "A", "kW", "%", "%", "度", "度", "kWh"
    ]
    const info = [{
        name: "当前电压"
    }, {
        name: "电流"
    }, {
        name: "功率"
    }, {
        name: "SOH"
    }, {
        name: "SOC"
    }, {
        name: "在线cell最高温"
    },
    {
        name: "在线cell最高低温"
    }, {
        name: "电池簇总电量"
    }]
    const historyList = ["电压", "总电流", "总功率", "SOH", "SOC", "温度(在线cell最大最小温度)"]


    const historyAcc = [{ time: "2012-01-09", content: "温度过高" }]
    const router = useNavigate()

    const gotoLibDetail=(id)=>{
        router(`/libraryDetail?id=${id}`)
        // todo
    }
    return <div className="bat-detail-con" >
        <div className="modal-title-s" >电池详情
        </div>
        <Divider></Divider>
        <Row>
            {
                info.map((item, key) => {
                    return <Col span={3} key={key} className="detail-inf-ti">
                        <div className="cont">
                            <div className="l1">{item.name}</div>
                            <div className="l2"><span>{valueArr[key]}</span><span className="l1">{unit[key]}</span></div>
                        </div>
                    </Col>
                })
            }
        </Row>
        <div className="flex mt10">
            <div className="flex-1">
                <div className="his-data">历史数据</div>
                <Row>
                    {
                        historyList.map((item, key) => {
                            return <Col span={8} key={key}>
                                <div>{item}</div>
                                {
                                    <ReactECharts style={{ height: "200px" }} option={optionState[key]} />
                                }
                            </Col>
                        })
                    }
                </Row>
                <div>
                    <DatePicker callback={callback}></DatePicker>
                </div>
            </div>
            <div className="flex-156">
                <div className="his-acci">历史故障</div>
                <div className="his-acci-cont">
                    {
                        dataSource.length>0?<div>
                            {
                                dataSource.map((item,index)=>{
                                    return <div  className="mb10 cur" key={index} onClick={()=>{gotoLibDetail(item.id)}}>{new dayjs(item.fault_time).format("YYYY-MM-DD")}: {item.tags.map((i,j)=>{
                                        return <Tag color="red"  key={j}>{i}</Tag>
                                    })}</div>
                                })
                            }
                        </div>: <Empty></Empty>
                    }
                   
                    {/* {
                    historyAcc.map((item, key) => {
                        return <div key={key}>
                            <span>{item.time}</span>
                            <span>{item.content}</span>
                        </div>
                    })
                } */}
                </div>
            </div>
        </div>
    </div>
}