import { Row, Col, Space, Card, Divider } from 'antd'
import ReactECharts from 'echarts-for-react';
import Layout from '../../component/LayoutCom'
import Header from '../../component/HeaderTab'
import Battery from '../../component/Battery'
import './index.scss'
import { useEffect, useState } from 'react';
import { customAxios } from '../../utils';
import dayjs from 'dayjs';
import $ from 'lodash'

export default function View() {
    const tab = [{ name: "首页", path: "" }, { name: "电池", path: "battery" }]
    const battery = [{
        name: "单元1",
        soc: 0.536666,
        soh: 0.6,
    }]
    const cardObj = {
        title1: { name: "1号机组母线A段", value: [250, 1, 399, 299] },
        title2: { name: "2号机组母线A段", value: [1, 8, 0, 7] },
        detailArr: [{
            title: [150, 20, 12, 24, 88],
            arr: [{ name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" },
            { name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" },
            { name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" },
            { name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" }]
        }, {
            title: [150, 20, 12, 24, 88],
            arr: [{ name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" },
            { name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" },
            { name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" },
            { name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" }]
        }, {
            title: [150, 20, 12, 24, 88],
            arr: [{ name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" },
            { name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" },
            { name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" },
            { name: "U1", soc: 0.75, model: "a", isRun: true, errType: "w", errMesg: "温度过高" }]
        }]
    }
    let arr = [cardObj, cardObj]
    const [arrState, setArrState] = useState(arr)
    const [errList, setErrList] = useState([])
    const getBData = () => {
        customAxios({
            method: "get",
            url: "/bbms/now"
        }).then((res) => {
            let json = res.data
            let a = [[], [], [], [], [], []]
            let err = []
            if (Array.isArray(json)) {
                json.forEach((item) => {
                    a[parseFloat(item.tags.bams) - 1][parseFloat(item.tags.bbms) - 1] = {
                        ...item,
                        name: item.tags.bams + "-" + item.tags.bbms,
                        soc: item.soc / 100,
                        status: item.status
                    }
                    if (item.abnormal.code !== 0) {
                        err.push(item)
                    }
                })
                console.log(err, "err")
                setErrList(err)
            }
            let temp = JSON.parse(JSON.stringify(arr))
            temp[0].detailArr = [{
                title: [150, 20, 12, 24, 88],
                arr: a[0]
            }, {
                title: [150, 20, 12, 24, 88],
                arr: a[1]
            }, {
                title: [150, 20, 12, 24, 88],
                arr: a[2]
            }]
            temp[1].detailArr = [{
                title: [150, 20, 12, 24, 88],
                arr: a[3]
            }, {
                title: [150, 20, 12, 24, 88],
                arr: a[4]
            }, {
                title: [150, 20, 12, 24, 88],
                arr: a[5]
            }]
            setArrState(temp)
        })
    }

    useEffect(() => {
        getBData()
    }, [])

    const AgcOption = {
        grid: {
            top: 30,
            bottom: 40,
            right: "20%"
        },
        xAxis: {
            type: 'category',
            data: []
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [],
                type: 'line',
                symbol: 'none',
            }
        ],
        tooltip: {
            show: true,
            trigger: "axis",
            valueFormatter: (value) => value + "MW"
        },
        dataZoom: {
            type: "slider"
        }
    }
    const canvasHeight = "200px"
    const [AgcOption1, setAgcOption1] = useState({
        ...AgcOption,
        legend: {
            name: ["电网指令", "火电出力", "联合出力"],
            orient: "vertical",
            right: 0
        },
        yAxis: {
            type: 'value',
            name: "MW",
            show: true
        },
    })
    const [AgcOption2, setAgcOption2] = useState({
        ...AgcOption,
        legend: {
            name: ["储能出力"],
            orient: "vertical",
            right: 0
        },
        yAxis: {
            type: 'value',
            name: "MW",
            show: true
        },
    })
    const [AgcOption3,setAgcOption3]=useState({
        ...AgcOption,
        tooltip: {
            show: true,
            trigger: "axis",
            valueFormatter: (value) => value + "元"
        },
        legend:{
            name:["收益"],
            orient: "vertical",
            right: 0
        },
        yAxis: {
            type: 'value',
            name: "元",
            show: true
        },
    })
    const [AgcOption4,setAgcOption4]=useState({
        ...AgcOption,
        tooltip: {
            show: true,
            trigger: "axis",
            valueFormatter: (value) => value 
        },
        legend:{
            name:["kp值"],
            orient: "vertical",
            right: 0
        },
        yAxis: {
            type: 'value',
            // name: "元",
            show: true
        },
    })
    const [AgcOption5,setAgcOption5]=useState({
        ...AgcOption,
        legend:{
            name:["里程"],
            orient: "vertical",
            right: 0
        },
        yAxis: {
            type: 'value',
            name: "MW",
            show: true
        },
    })
    const getData = () => {
        customAxios({
            method: "get",
            url: "/agc/today"
        }).then((res) => {
            let json = res.data
            let temp1 = JSON.parse(JSON.stringify(AgcOption2))
            let temp = JSON.parse(JSON.stringify(AgcOption1))
            let time = [], agc_demand = [], plant_p = [], ess_plant_p = [], ess_p = []
            let min = Infinity, max = 0
            json.data.forEach((item, index) => {
                time.push(new dayjs(item.time).format("HH:mm:ss"))
                plant_p.push(item.plant_p)
                agc_demand.push(item.agc_demand)
                ess_plant_p.push(item.ess_plant_p)
                ess_p.push(item.ess_p)
            })
            plant_p.concat(agc_demand).concat(ess_plant_p).forEach((item) => {
                if (item < min) {
                    min = item
                }
                if (item > max) {
                    max = item
                }
            })
            temp.yAxis.min = parseFloat((min * 0.95).toFixed(0))
            temp.yAxis.max = parseFloat((max * 1.05).toFixed(0))
            temp1.xAxis.data = time
            temp1.series[0].name = "储能出力"
            temp1.series[0].data = ess_p
            temp.xAxis.data = time
            temp.series[0].name = "电网指令"
            temp.series[0].data = agc_demand
            temp.series[1] = {
                name: "火电出力",
                data: plant_p,
                type: "line",
                symbol: "none",
            }
            temp.series[2] = {
                name: "联合出力",
                data: ess_plant_p,
                type: "line",
                symbol: "none",
            }
            setAgcOption1(temp)
            setAgcOption2(temp1)
        })
    }
    //日均kp
    const [aveKP,setAveKP]=useState(0)
    const [avePri,setAvePri]=useState(0)
    //获取收益
    const getProfit=()=>{
        customAxios({
            method:"get",
            url:"/profit/today"
        }).then((res)=>{
            let json=res.data.data
            if(Array.isArray(json)){
                let  delta_q=[],profit=[],kp=[],x=[]
                json.forEach((item,key)=>{
                    x.push(new dayjs(item.time).format("HH:mm:ss") )
                    delta_q.push(item.delta_q)
                    profit.push(item.profit)
                    kp.push(item.kp)
                })
                let all= kp.reduce((s,i)=>{
                    if(i>0){
                    return s+i
                    }else{
                        return s
                    }
                },0)
                let allP=profit.reduce((s,i)=>{
                    return s+i
                },0)
                setAvePri(Math.floor(allP))
                setAveKP( kp.length>0?parseFloat((all/kp.filter((item)=>item>-1).length).toFixed(3)) :0)
                let temp3=JSON.parse(JSON.stringify(AgcOption3))
                let temp4=JSON.parse(JSON.stringify(AgcOption4))
                let temp5=JSON.parse(JSON.stringify(AgcOption5))
                // temp4.yAxis.min=
                
                temp3.series[0].data=profit
                temp3.series[0].name="收益"
                temp4.series[0].data=kp
                temp4.series[0].name="kp值"
                temp4.yAxis.min=parseFloat(($.cloneDeep(kp).sort((a,b)=>a-b)[0]*0.95).toFixed(2))
                temp5.series[0].data=delta_q
                temp5.series[0].name="里程"
                temp3.xAxis.data=x
                temp4.xAxis.data=x
                temp5.xAxis.data=x
                setAgcOption3(temp3)
                setAgcOption4(temp4)
                setAgcOption5(temp5)
            }
        })
    }
    useEffect(() => {
        getData()
        getProfit()
    }, [])
    return <Layout>
        <div className='home-page'>
            <div className='tit-bor'>
                <Row>
                    <Col span={16}>
                        <div className='ml40 yc-bat'>
                            <svg className='icon' aria-hidden="true" style={{ fontSize: "28px" }}>
                                <use xlinkHref='#icon-weixiu'></use>
                            </svg>
                            <span className='font-nor'> 异常电池数量 </span><span style={{ fontSize: "32px" }}>{errList.length}</span>
                        </div>
                    </Col>
                    <Col span={8} >
                        <div className='flex'>
                            <div className='flex-20'>
                                <div className='divi-tit'>
                                    <div className='line-h5 div-top' >. .</div>
                                    <div className='line-h5'>. .</div>
                                    <div className='line-h5'>. .</div>
                                </div>
                            </div>
                            <div className='flex-1'>
                                <Row className='tc'>
                                    <Col span={12} >
                                        <div className='dr-shouyi'>
                                            <svg className='icon' aria-hidden="true" style={{ fontSize: "28px" }}>
                                                <use xlinkHref='#icon-shouyi'></use>
                                            </svg>
                                            <span className='font-nor'> 当日收益 </span><span style={{ fontSize: "32px" }}>{avePri}</span> <span className='font-nor'>元</span>   </div>

                                    </Col>
                                    <Col span={12}>
                                        <div className='current-kp'>
                                            <svg className='icon' aria-hidden="true" style={{ fontSize: "28px" }} >
                                                <use xlinkHref='#icon-jiankongzhibiao'></use>
                                            </svg>
                                            <span className='font-nor'> 日均KP </span>  <span style={{ fontSize: "32px" }}>{aveKP}</span> </div>
                                    </Col>
                                </Row>

                            </div>
                        </div>


                    </Col>
                </Row>
            </div>
            <Row>
                <Col span={16}>
                    {
                        arrState.map((item, key) => {
                            return <div key={key} >
                                <BatteryBorad obj={item}></BatteryBorad>
                            </div>
                        })
                    }
                </Col>
                <Col span={8}>
                    <Card className='card-style'>
                        <div className='echart-title'>电网指令&火电出力&联合出力</div>
                        <ReactECharts style={{ height: canvasHeight }} option={AgcOption1} />
                        <div className='echart-title'>储能出力</div>
                        <ReactECharts style={{ height: canvasHeight }} option={AgcOption2} />
                      
                        <Row >
                            <Col span={12}>
                                <div className='echart-title'>kp值</div>
                                <ReactECharts style={{ height: canvasHeight }} option={AgcOption4} />
                            </Col>
                            <Col span={12}>
                                <div className='echart-title'>调频里程</div>
                                <ReactECharts style={{ height: canvasHeight }} option={AgcOption5} />
                            </Col>
                        </Row>
                        <div className='echart-title'>参考收益</div>
                        <ReactECharts style={{ height: canvasHeight }} option={AgcOption3} />
                    </Card>
                </Col>
            </Row>
        </div>
    </Layout>
}

//运行状态图例
function RunStateSimp() {
    const arr = ['正在运行', "暂停工作", '自动模式', '手动模式']
    const icon = ["icon-bofang1", "icon-zanting", "icon-youxuzidonghua", "icon-caozuo-shoudong"]
    return <div className='tr-run'>
        <Space>
            {
                arr.map((item, key) => {
                    return <div className={`run-state${key}`} key={key}>
                        <svg className='icon' aria-hidden="true" >
                            <use xlinkHref={`#${icon[key]}`}></use>
                        </svg>
                        {item}</div>
                })
            }
        </Space>
    </div>
}

//电池看板
function BatteryBorad(props) {
    const { obj = {} } = props
    const { title1, title2, detailArr } = obj
    let titleArr = [title1, title2]
    const pqab = ["P", "Q", "la", "lb"]
    const pqabc = ["P", "Q", "la", "lb", "lc"]
    return <Card className='mb20'>
        <div >
            <div className='tit-bac'>
                <div className='card-pqab' style={{ display: "none" }}>
                    {
                        titleArr.map((item, key) => {
                            return <div key={key}>
                                {
                                    item.name
                                }
                                {
                                    pqab.map((i, j) => {
                                        return <span key={j}>{i}:{item.value[j]}</span>
                                    })
                                }
                            </div>
                        })
                    }
                </div>
                <RunStateSimp></RunStateSimp>
            </div>
            <Divider style={{ marginTop: "1px", marginBottom: "12px", color: "#BFBFBF" }}></Divider>
            <Row >
                {
                    detailArr.map((item, key) => {
                        return <Col key={key} span={8} >
                            <div className={`${key == 0 ? "tc" : "tc left-line"}`}>
                                <Row style={{ padding: "0 40px", display: "none" }} >

                                    {
                                        [pqabc[0], pqabc[1]].map((i, k) => {
                                            return <Col span={12} key={k}> <div>
                                                {i}:{item.title[k]}
                                            </div>
                                            </Col>
                                        })
                                    }
                                    {
                                        [pqabc[2], pqabc[3], pqabc[4]].map((i, k) => {
                                            return <Col span={8} key={k}> <div >
                                                {i}:{item.title[k + 2]}
                                            </div></Col>
                                        })
                                    }
                                </Row>
                                {item.arr.map((item, key) => {
                                    return <div key={key} className='bat-all-b'>
                                        <Space>
                                            <Battery name={item.name} soc={item.soc}></Battery>
                                            <div className='run-model'>
                                                {/* <Space> */}
                                                <div>{item.status == 3 ? <svg className='icon' style={{ color: "#52C41A" }} aria-hidden="true" >
                                                    <use xlinkHref="#icon-bofang1"></use>
                                                </svg> : <svg className='icon' aria-hidden="true" >
                                                    <use xlinkHref="#icon-zanting" style={{ color: "#FF4D4F" }}></use>
                                                </svg>}</div>
                                                <div>{item.model !== "a" ? <svg className='icon' aria-hidden="true" >
                                                    <use xlinkHref="#icon-youxuzidonghua"></use>
                                                </svg> : <svg className='icon' aria-hidden="true" >
                                                    <use xlinkHref="#icon-caozuo-shoudong"></use>
                                                </svg>}</div>
                                                {/* </Space> */}
                                            </div>
                                        </Space>
                                        <div style={{ fontSize: "8px", marginBottom: "2px" }}>
                                            <Space >
                                                <div>电压: <br />{item.u}V</div>
                                                <div>电流:<br />{item.i}A</div>
                                                <div>功率:<br />{item.p}kW</div>
                                                {item.soe && <div>电量:<br />{item.soe.toFixed()}kWh</div>}
                                                <div>SOH:<br />{item.soh}%</div>
                                            </Space>
                                        </div>

                                    </div>
                                })}
                            </div>
                        </Col>
                    })
                }
            </Row>
        </div>
    </Card>
}

// const cardObj={
//     title1:{name:"1号机组母线A段",value:[250,1,399,299]},
//     title2:{name:"2号机组母线A段",value:[1,8,0,7]},
//     detailArr:[{
//         title:[],
//         arr:[{name:"U1",soc:0.75,model:"a",isRun:true,errType:"w",errMesg:"温度过高"}]
//     }]
// }
