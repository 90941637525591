import axios from "axios"
export function customAxios(param) {
    let origin = "https://qiaokou.tsingroc.com/api"
    if (process.env.REACT_APP_ORIGIN) {
        origin = process.env.REACT_APP_ORIGIN
    }
    return axios({
        ...param,
        url: origin + param.url,
        headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`},
    })
}