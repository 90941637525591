import FormRender, { useForm } from 'form-render';

import { Button, Card, Modal, Popconfirm, Table, Tag, Upload, message } from 'antd';


import Layout from "../../component/LayoutCom";
import Header from '../../component/HeaderTab'

import './index.scss'
import { useEffect, useState } from 'react';
import { customAxios } from '../../utils';
import dayjs from 'dayjs';

//故障知识库
export default function View() {

    const schema = {
        type: 'object',
        column: 4,
        displayType: 'row',
        properties: {
            num: {
                title: '设备编号',
                type: 'string',
                widget: 'input',
            },
            type: {
                title: '维护类型',
                type: 'string',
                widget: 'input',
            },
            operate: {
                title: '检修人',
                type: 'string',
                widget: 'input',
            },
            detail: {
                title: '检修详情',
                type: 'string',
                widget: 'input',
                props: {
                    placeholder: "模糊查询"
                }
            },
            state: {
                title: "状态",
                type: 'string',
                widget: 'select',
                props: {
                    options: [
                        { label: '全部', value: -1 },
                        { label: '已处理', value: 1 },
                        { label: '未处理', value: 0 }
                    ]
                }
            },
            createTime: {
                title: '记录创建时间',
                type: 'range',
                format: "date",
                widget: 'dateRange'
            },
            startTime:{
                title:"开始时间",
                type: 'range',
                format: "date",
                widget: 'dateRange'
            },
            finishTime: {
                title: '完成时间',
                type: 'range',
                format: "date",
                widget: 'dateRange'
            },
        }
    }
    const form = useForm();
    const onFinish = (formData) => {
        console.log('formData:', formData);
    };
    const [dataSourceState, setDataSourceState] = useState([])

    //删除记录
    const deleteRecord = (id) => {
        customAxios({
            method: "delete",
            url: `/maintenances/${id}`
        }).then((res) => {
            message.success("删除成功！")
            getList()
        }).catch(() => {
            message.error("删除失败！")
        })
    }
    const columns = [
        {
            title: '创建时间',
            dataIndex: 'createtime',
            key: 'createtime',
            align:"center",
        },
        {
            title: "开始时间",
            dataIndex: "started_at",
            key: "started_at",
            align:"center",
        },
        {
            title: '完成时间',
            dataIndex: 'finishtime',
            key: 'finishtime',
            align:"center",
        },
        {
            title: "设备",
            dataIndex: "num",
            align:"center",
            key: "num"
        },
        {
            title: '备注',
            dataIndex: 'remark',
            align:"center",
            key: 'remark',
        },
        {
            title: '检修详情',
            dataIndex: 'detail',
            align:"center",
            key: 'detail',
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            align:"center",
        },
        {
            title: '检修人',
            dataIndex: 'operate',
            align:"center",
            key: 'operate',
        }, {
            title: '状态',
            dataIndex: 'state',
            align:"center",
            key: 'state',
            render: (text) => {
                if (text == 0) {
                    return <Tag color="magenta">未处理</Tag>
                } else if (text == 1) {
                    return <Tag color='blue'>已处理</Tag>
                }
            }
        }, {
            title: '操作',
            dataIndex: 'op',
            align:"center",
            key: 'op',
            render: (text, record) => {
                return <>
                    <a onClick={() => {
                        setModal(true)
                        setOverhauID(record.id)
                    }}>
                        {record.state == 0 ? "处理" : "查看"}
                    </a>
                    <Popconfirm title="确定删除？" onConfirm={() => { deleteRecord(record.id) }}>
                        <Button danger type='link'>删除</Button>
                    </Popconfirm>
                </>
            }
        }
    ]
    useEffect(() => {
        reset()
    }, [])
    //重置
    const reset = () => {
        form.setValues({
            startTime:[],
            createTime: [],
            num: undefined,
            finishTime: [],
            operate: undefined,
            detail: undefined,
            state: -1,
            type:undefined
        })
        getList({})
    }
    //查询
    const getList = (params) => {
        customAxios({
            method: "get",
            url: "/maintenances",
            params: {
                ...params,
                desc:true,
                sort_by:"created_at",
                pagesize: 10000
            }
        }).then((res) => {
            let json = res.data.data
            if (Array.isArray(json)) {
                let arr = json.map((item, key) => {
                    return {
                        ...item,
                        key: (key + 1).toString(),
                        createtime: item.created_at ? new dayjs(item.created_at).format("YYYY-MM-DD HH:mm:ss") : undefined,
                        finishtime: item.completed_at ? new dayjs(item.completed_at).format("YYYY-MM-DD HH:mm:ss") : undefined,
                        num: item.devices ? item.devices.join(",") : "",
                        operate: item.operators ? item.operators.join(",") : "",
                        type: item.type,
                        state: item.state,
                        remark: item.comment,
                        started_at: item.started_at ? new dayjs(item.started_at).format("YYYY-MM-DD HH:mm:ss") : undefined
                    }
                })
                setDataSourceState(arr)

            }else{
                setDataSourceState([])
            }
            console.log(json, "json")
        })
    }
    useEffect(() => {
        getList({})
    }, [])

    const [modal, setModal] = useState(false)
    const callback = () => {
        setModal(false)
        getList({})
        setOverhauID("")
    }
    const [overhauID, setOverhauID] = useState("")

    const download = () => {
        let origin = "https://qiaokou.testing.tsingroc.com/api"
        if (process.env.REACT_APP_ORIGIN) {
            origin = process.env.REACT_APP_ORIGIN
        }
        return origin + `/maintenances_excel/维护记录导入模板.xlsx`
    }

    const uploadProps = {
        // fileList: [],
        name: "file",
        showUploadList:false,
        action: process.env.REACT_APP_ORIGIN ? process.env.REACT_APP_ORIGIN + "/maintenances_excel" : "https://qiaokou.testing.tsingroc.com/api/maintenances_excel",
        onChange: (info) => {
            if(info.file.status=="done"){
                if (Array.isArray(info.file.response)) {
                    message.success("上传文件成功！")
                    getList({})
                } else {
                    message.warning("文件上传失败")
                }
            }
            console.log(info)
        }
    }
    const search = () => {
        const {startTime=[], createTime = [],num,finishTime = [],operate,detail, state = -1 ,type} = form.getValues()
        getList({
            started_at_begin:startTime.length>0?new dayjs(startTime[0]).toISOString():undefined, 
            started_at_end:startTime.length>0?new dayjs(startTime[1]).add(1,"day").toISOString():undefined, 
            comment:detail,
            completed_at_begin:finishTime.length>0?new dayjs(finishTime[0]).toISOString():undefined, 
            completed_at_end:finishTime.length>0?new dayjs(finishTime[1]).add(1,"day").toISOString():undefined, 
            created_at_begin:createTime.length>0?new dayjs(createTime[0]).toISOString():undefined, 
            created_at_end:createTime.length>0?new dayjs(createTime[1]).add(1,"day").toISOString():undefined, 
            device:num,
            operator:operate,
            state:state==-1?undefined:parseInt(state) ,
            type:type
        })
        console.log(form.getValues(), "form.getValues()")
    }

    return <Layout>
        <div className='page-overhau'>
            <Modal width={800} open={modal} onCancel={() => {
                setModal(false)
                setOverhauID("")
            }} destroyOnClose={true} title={!overhauID ? "创建检修单" : "检修详情"} footer={null}>
                <OverDetail overhauID={overhauID} callback={callback}></OverDetail>
            </Modal>
            <Card className='mt20'>
                <div className='mt20'>
                <FormRender
                    form={form}
                    schema={schema}
                    onFinish={onFinish}
                    maxWidth={360}
                    footer={false}
                />
                </div>
                <div className='tr' style={{marginRight:"15px"}}><Button className='mr20' onClick={reset}>重置</Button><Button type='primary' onClick={search} >查询</Button></div>
            </Card>
            <Card className='mt20'>
                <div className='op-butt'  ><Button className='mr20' type='primary' onClick={() => { setModal(true) }}>+ 创建检修记录</Button><Button className='mr20'  ><a href={download()}>模板下载</a> </Button> <Upload {...uploadProps}><Button type='primary'> 批量导入</Button></Upload> </div>
                <Table size='small' dataSource={dataSourceState} columns={columns} />
            </Card>
        </div>
    </Layout>
}

function OverDetail(props) {
    const { overhauID, callback } = props
    const form = useForm();
    const reset = () => {
        form.setValues({})
    }
    const schema = {
        type: 'object',
        displayType: 'row',
        labelWidth: 100,
        properties: {
            num: {
                title: '设备编号',
                type: 'string',
                widget: 'input',

            },
            operate: {
                title: '检修人',
                type: 'string',
                widget: 'input',
            },
            timeStart: {
                title: "检修开始日期",
                type: "string",
                widget: "datePicker"
            },
            timeStartT: {
                title: "检修开始时间",
                type: "string",
                format:"time",
                widget: "timePicker"
            },
            timeFinish: {
                title: "检修完成日期",
                type: "string",
                widget: "datePicker"
            },
            timeFinishT: {
                title: "检修完成时间",
                type: "string",
                format:"time",
                widget: "timePicker"
            },
            detail: {
                title: "备注",
                type: "string",
                widget: "textArea"
            },
            type: {
                title: "检修类型",
                type: "string",
                widget: "input"
            },
            state: {
                title: "状态",
                type: "string",
                widget: "radio",
                props: {
                    options: [
                        {
                            label: "已处理",
                            value: "1"
                        }, {
                            label: "未处理",
                            value: "0"
                        }
                    ]
                }
            },
        }
    }
    const onFinish = () => { }
    const [schemaState, setSchemaState] = useState(schema)
    const [notUpdate, setNotUpdate] = useState(false)

    const getDetail = () => {
        customAxios({
            method: "get",
            url: `/maintenances/${overhauID}`,
        }).then((res) => {
            let json = res.data
            const { started_at, operators = [], state, type, completed_at, devices = [], comment } = json
            form.setValues({
                num: devices.join(","),
                operate: operators.join(","),
                timeStart: started_at ? new dayjs(started_at).format("YYYY-MM-DD") : undefined,
                timeStartT: started_at ? new dayjs(started_at).format("HH:mm:ss") : undefined,
                timeFinish: completed_at ? new dayjs(completed_at).format("YYYY-MM-DD") : undefined,
                timeFinishT: completed_at ? new dayjs(completed_at).format("HH:mm:ss") : undefined,
                detail: comment,
                type: type,
                state: state ? state.toString() : "0"
            })
            if (state == 1) {
                setNotUpdate(true)
            }
        })
    }
    useEffect(() => {
        if (overhauID) {
            getDetail()
        }
    }, [overhauID])

    //更新检修记录
    const updateOverhau = () => {
        createOverhau("UP")
    }

    //创建检修记录
    const createOverhau = (t) => {
        const { num, operate, timeStart, timeFinish,timeStartT=" 00:00:00", timeFinishT=" 00:00:00", detail, type, state } = form.getValues()
        if (!num) {
            message.warning("设备编号必填！")
            return
        }
        if (state == 1 && !timeFinish) {
            message.warning("请选择完成时间！")
            return
        }
        if (timeFinish && state == 0) {
            message.warning("检修状态请设置为已完成！")
            return
        }
        customAxios({
            method: t == "UP" ? "put" : "post",
            url: t == "UP" ? `/maintenances/${overhauID}` : "/maintenances",
            data: {
                comment: detail,
                completed_at: timeFinish ? new dayjs(timeFinish+timeFinishT).toISOString() : undefined,
                devices: num ? num.split(",") : [],
                operators: operate ? operate.split(",") : [],
                started_at: timeStart ? new dayjs(timeStart+timeStartT).toISOString() : undefined,
                state: !state ? 0 : state == "0" ? 0 : 1,
                type: type
            }
        }).then(() => {
            callback && callback()
        })
    }
    const ok = () => {
        if (overhauID) {
            updateOverhau()
        } else {
            createOverhau()
        }
    }

    return <div>
        <FormRender
            form={form}
            schema={schemaState}
            onFinish={onFinish}
            footer={false}
        />
        <div className='tr'>
            {!notUpdate && <Button type='primary' onClick={ok}>确定</Button>}
        </div>
    </div>
}