import FormRender, { useForm } from 'form-render';
import ReactECharts from 'echarts-for-react';
import { useNavigate } from 'react-router-dom'

import Layout from "../../component/LayoutCom";
import { Button, Card, Modal, Table, Tag, Input, message, Popconfirm, Upload, Radio, DatePicker, Tooltip } from 'antd';
import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons"

import './index.scss'
import { useEffect, useState } from 'react';
import { customAxios } from '../../utils';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

//故障知识库
export default function View() {
    const form = useForm();
    const router = useNavigate()
    useEffect(() => {
        resetForm()
    }, [])
    //重置
    const resetForm = () => {
        form.setValues({ unit: "", state: -1, detail: "", time: [], fault_type: "", operator: "", tag: "" })
        search()
    }
    //查询
    const search = () => {
        const formValues = form.getValues()
        const { time = [], unit, state, detail, fault_type, operator, tag } = formValues
        let params = {
            start: time.length > 0 ? new dayjs(time[0]).toISOString() : undefined,
            end: time.length > 0 ? new dayjs(time[1]).add(1,"day").toISOString() : undefined,
            description: detail,
            device: unit,
            fault_type: fault_type,
            operator: operator,
            state: state == -1 ? undefined : state,
            tag: tag
        }
        getlist(params)
    }
    const schema = {
        type: 'object',
        column: 4,
        displayType: 'row',
        properties: {
            time: {
                title: '故障时间',
                type: 'range',
                format: "date",
                widget: 'dateRange'
            },
            unit: {
                title: "设备编号",
                type: 'string',
                widget: 'input',
            },
            state: {
                title: "状态",
                type: 'string',
                widget: 'select',
                props: {
                    options: [
                        { label: '全部', value: -1 },
                        { label: "已处理", value: 1 },
                        { label: "未处理", value: 0 }
                    ]
                }
            },
            detail: {
                title: '故障详情',
                type: 'string',
                widget: 'input',
                props: {
                    placeholder: "模糊搜索"
                }
            },
            fault_type: {
                title: "故障类型",
                type: "string",
                widget: "select",
                props:{}
            },
            tag: {
                title: "故障标签",
                type: "string",
                widget: "select",
                props:{}
            },
            operator: {
                title: "操作员",
                type: "string",
                widget: "input"
            },
        }
    }
    const [schemaState,setSchemaState]=useState(schema)

    const getTags = () => {
        customAxios({
            method: "get",
            url: "/fault_tags"
        }).then((res) => {
            let json = res.data
            if (Array.isArray(json)) {
                // setTagsArr(json)
                let arrType = [],tagArr=[]
                json.forEach((item) => {
                    tagArr.push(item.tag)
                    if (!arrType.includes(item.fault_type)) {
                        arrType.push(item.fault_type)
                    }
                })
                let temp = JSON.parse(JSON.stringify(schemaState))
                temp.properties.tag.props.options = tagArr.map((item) => {
                    return {
                        label: item,
                        value: item,
                    }
                })
                temp.properties.fault_type.props.options = arrType.map((item) => {
                    return {
                        label: item,
                        value: item,
                    }
                })
                setSchemaState(temp)
            }
            console.log(json, "json")
        })
    }

    const onFinish = (formData) => {
        console.log('formData:', formData);
    };

    const source = [
    ];

    const deleteAcc = (id) => {
        customAxios({
            method: "delete",
            url: `/faults/${id}`
        }).then(() => {
            message.success("删除成功！")
            getlist()
        })
    }

    const downloadFile = (arr) => {
        if (arr.length !== 2) return
        let origin = "https://qiaokou.testing.tsingroc.com/api"
        if (process.env.REACT_APP_ORIGIN) {
            origin = process.env.REACT_APP_ORIGIN
        }
        return origin + `/file/${arr[1]}?filename=${arr[1]}&id=${arr[0]}`
    }
    const columns = [
        {
            title: '故障时间',
            dataIndex: 'time',
            key: 'time',
            align: "center"
        },
        {
            title: '故障类型',
            dataIndex: 'type',
            key: 'type',
            align: "center"
        },
        {
            title: '故障标签',
            dataIndex: 'tags',
            key: 'tags',
            align: "center",
            render: (text) => {
                return <div>
                    {text && text.map((i, j) => {
                        return <Tag key={j}>{i}</Tag>
                    })}
                </div>
            }
        },
        {
            title: '设备编号',
            dataIndex: 'num',
            key: 'num',
            align: "center"
        },
        {
            title: '故障详情',
            dataIndex: 'detail',
            key: 'detail',
            align: "center",
            render: (text, record) => { return <div className='max-200' onClick={() => { router(`/libraryDetail?id=${record.id}`) }}><Tooltip  placement="left" title={text}><a>{text}</a></Tooltip>  </div> }
        },
        {
            title: '处理详情',
            dataIndex: 'dealDetail',
            key: 'dealDetail',
            align: "center",
            render: (text) => {
                return <div className='max-200'><Tooltip placement='left' title={text}>{text}</Tooltip> </div>
            }
        },
        {
            title: '文件',
            dataIndex: 'attachments',
            key: 'attachments',
            align: "center",
            render: (text) => {
                return <div className='file-table-cell'>
                    {text && text.map((item, key) => {
                        let arr = item.split("|")
                        return <div key={key} className='file-item-cell'> <Tooltip title={arr[1]}><a href={downloadFile(arr)}>{arr[1]}</a></Tooltip> </div>
                    })}
                </div>
            }
        },
        {
            title: '处理人',
            dataIndex: 'operater',
            key: 'operater',
            align: "center",
        }, {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            align: "center",
            render: (text) => {
                return <div>
                    {
                        text == 0 && <Tag color="magenta" >未处理</Tag>
                    }
                    {
                        text == 1 && <Tag color="blue">已处理</Tag>
                    }

                </div>
            }
        }, {
            title: '操作',
            dataIndex: 'op',
            key: 'op',
            align: "center",
            render: (text, r) => {
                return <div>
                    {r.state == 0 && <><a onClick={() => {
                        setAccID(r.id)
                        setModal(true)
                    }}>去处理</a>
                        {/* <a onClick={() => { router(`/libraryDetail?id=${r.id}`) }}> 查看</a> */}
                        <Popconfirm title="确认删除？" onConfirm={() => { deleteAcc(r.id) }}><Button danger type='link'>删除</Button></Popconfirm>
                    </>}
                    {r.state == 1 && <><a onClick={() => { router(`/libraryDetail?id=${r.id}`) }}>查看</a><Popconfirm title="确认删除？" onConfirm={() => { deleteAcc(r.id) }}><Button danger type='link'>删除</Button></Popconfirm> </>}
                </div>
            }
        }
    ];
    const [dataSource, setDataSource] = useState(source)
    //新增或修改弹窗
    const [modal, setModal] = useState(false)
    //获取页面数据
    const getlist = (params) => {
        customAxios(
            {
                method: "get",
                url: "/faults",
                params: {
                    ...params,
                    desc: true,
                    sort_by: "fault_time",
                    pagesize: 100000
                }
            }
        ).then((res) => {
            let json = res.data.data
            if (Array.isArray(json)) {
                let arr = json.map((item, key) => {
                    return {
                        ...item,
                        key: (key + 1).toString(),
                        operater: item.operator && item.operator.join(","),
                        time: new dayjs(item.fault_time).format("YYYY-MM-DD HH:mm:ss"),
                        detail: item.description,
                        num: item.device,
                        dealDetail: item.operation

                    }
                })
                setDataSource(arr)
            }else{
                setDataSource([])
            }
        })
    }
    const [accID, setAccID] = useState("")
    useEffect(() => {
        getlist({})
        getTags()
    }, [])
    //故障频率统计弹窗
    const [frequencyModal, setFrequencyModal] = useState(false)
    const download = () => {
        let origin = "https://qiaokou.testing.tsingroc.com/api"
        if (process.env.REACT_APP_ORIGIN) {
            origin = process.env.REACT_APP_ORIGIN
        }
        return origin + `/faults_excel/故障记录导入模板.xlsx`
    }
    const uploadPropsE = {
        // fileList: [],
        name: "file",
        showUploadList: false,
        action: process.env.REACT_APP_ORIGIN ? process.env.REACT_APP_ORIGIN + "/faults_excel" : "https://qiaokou.testing.tsingroc.com/api/faults_excel",
        onChange: (info) => {
            if (info.file.status == "done") {
                if (Array.isArray(info.file.response)) {
                    message.success("上传文件成功！")
                    getlist({})
                } else {
                    message.warning("文件上传失败")
                }
            }
            console.log(info)
        }
    }
    const [addTag, setAddTag] = useState(false)
    return <Layout>
        <Modal title="新增故障标签"
            onCancel={() => { setAddTag(false) }}
            open={addTag}
            footer={null}
        >
            <AddTag callback={() => { setAddTag(false) }}></AddTag>
        </Modal>
        <Modal
            destroyOnClose={true}
            width={1200}
            title="故障频率统计"
            forceRender={true}
            onCancel={() => {
                setFrequencyModal(false)
            }}
            open={frequencyModal}
            footer={null}>
            <Frequency ></Frequency>
        </Modal>
        <Modal width={800} destroyOnClose={true} open={modal} title={accID ? "处理故障记录" : "新增故障记录"} onCancel={() => {
            setModal(false)
            setAccID("")
        }} footer={null}>
            <AddRecord accID={accID} callback={() => {
                setAccID("")
                setModal(false)
                getlist()
            }}>
            </AddRecord>
        </Modal>
        {/* <Header ></Header> */}
        <div className='page-library'>
            <Card className='mt20 mb20'>
                <div className='mt20'>
                    <div className='fr mr20 ser-button'>
                        <Button className='mr20' onClick={resetForm}>重置</Button>
                        <Button type='primary' onClick={search}>查询</Button>
                    </div>
                    <FormRender
                        form={form}
                        schema={schemaState}
                        onFinish={onFinish}
                        maxWidth={360}
                        footer={false}
                    />

                </div>
            </Card>
            <div className='pb20'>
                <Card className=''>
                    <div className='op-butt'>
                        <Button className='mr20' onClick={() => { setAddTag(true) }} >新增故障标签</Button>
                        <Button type='primary' className='mr20' onClick={() => { setFrequencyModal(true) }}>故障频率统计</Button>
                        <Button className='mr20' type='primary' onClick={() => { setModal(true) }}>+ 新增记录</Button>
                        <Button className='mr20'>  <a href={download()}>模板下载</a> </Button>
                        <Upload {...uploadPropsE}><Button type='primary'> 批量导入</Button></Upload>
                    </div>
                    <Table size='small' dataSource={dataSource} columns={columns} />
                </Card>
            </div>
        </div>
    </Layout>
}

//故障频率统计弹窗
function Frequency() {
    const AgcOption = {
        xAxis: {
            type: 'category',
            data: ['U1-2', 'U2-2', 'U1-22', 'U11-2', 'U21-2', 'U14-2', 'U18-2']
        },
        yAxis: {
            type: 'value',
            minInterval: 1
        },
        series: [
            {
                data: [120, 200, 150, 80, 70, 110, 130],
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
            }
        ]
    }
    const [AgcOptionState, setAgcOptionState] = useState(AgcOption)
    const [value, setValue] = useState("device")
    const options = [{
        label: "设备", value: "device"
    }, {
        label: "故障种类", value: "type"
    }, {
        label: "处理状态", value: "state"
    }]
    //时间
    const [time, setTime] = useState([])
    //获取数据
    const getData = () => {
        customAxios({
            method: "get",
            url: "/faults_group/count",
            params: {
                field: value,
                start: time.length > 0 ? time[0].toISOString() : undefined,
                end: time.length > 0 ? time[1].toISOString() : undefined
            }
        }).then((res) => {
            let json = res.data
            if (Array.isArray(json)) {
                let x = [], y = []
                let temp = JSON.parse(JSON.stringify(AgcOption))
                json.forEach((item) => {
                    x.push(item.group)
                    y.push(item.result)
                })
                temp.xAxis.data = value == "state" ? x.map((item) => {
                    return ["未处理", "已处理"][item]
                }) : x
                temp.series[0].data = y
                setAgcOptionState(temp)
            }
        })
    }
    useEffect(() => {
        if (value) {
            getData()
        }
    }, [value, time])
    const onChange = (e) => {
        setValue(e.target.value)
    }
    return <div>
        <div className='tr'>
            {/* todo */}
            <RangePicker className='mr20' value={time} onChange={(value) => { setTime(value) }}></RangePicker>
            <Radio.Group options={options} onChange={onChange} value={value} optionType="button" buttonStyle="solid" />
        </div>
        <ReactECharts option={AgcOptionState} />
    </div>
}

//新增标签
function AddTag(props) {
    const { callback } = props
    const [tagName, setTagName] = useState("")
    const [tagType, setTagType] = useState("")
    const addTag = () => {
        if (!tagName) {
            message.warning("请输入标签名称")
            return
        }
        if (!tagType) {
            message.warning("请输入标签类型")
            return
        }
        customAxios({
            method: "post",
            url: "/fault_tags",
            data: {
                fault_type: tagType,
                tag: tagName
            }
        }).then((res) => {
            if (res.data && res.data.id) {
                message.success("标签创建成功！")
                callback && callback()
            }
        })

    }
    return <div>
        <div className='mt5'>
            <span>故障类型：</span>
            <Input
                value={tagType} style={{ width: "100px", marginRight: "5px" }}
                onChange={(e) => { setTagType(e.target.value) }}
            ></Input>
            <span>故障标签：</span>
            <Input style={{ width: "100px", marginRight: "5px" }} value={tagName}
                onChange={(e) => { setTagName(e.target.value) }}>
            </Input>
            <Button className='ml20' type='primary' onClick={addTag}>添加</Button> </div>
    </div>
}

//新增事故记录
function AddRecord(props) {
    const { callback, accID } = props

    const [tagsArr, setTagsArr] = useState([])
    //获取故障详情
    const getAccDetail = () => {
        customAxios({
            method: "get",
            url: `/faults/${accID}`
        }).then((res) => {
            let json = res.data
            const { description, device, operation, operator = [], state, type, fault_time, tags=[], attachments = [] } = json
            form.setValues({
                detail: description,
                num: device,
                deal: operation,
                operate: operator.join(","),
                state: state.toString(),
                type: type,
                timeOccur: new dayjs(fault_time).format("YYYY-MM-DD"),
                timeOccurT: new dayjs(fault_time).format("HH:mm:ss"),
                tags:tags.join(",")
            })
            let temp = attachments.map((item) => {
                let arr = item.split("|")
                return {
                    name: arr[1],
                    status: "done",
                    response: {
                        id: arr[0]
                    }
                }
            })
            setFileList(temp)
            if (tags) {
                setTagShow(tags)
            }
        })
    }
    const [tagShow, setTagShow] = useState([])
    const getTags = () => {
        customAxios({
            method: "get",
            url: "/fault_tags"
        }).then((res) => {
            let json = res.data
            if (Array.isArray(json)) {
                setTagsArr(json)
                let arrType = []
                json.forEach((item) => {
                    if (!arrType.includes(item.fault_type)) {
                        arrType.push(item.fault_type)
                    }
                })
                let temp = JSON.parse(JSON.stringify(schemaState))
                temp.properties.type.props.options = arrType.map((item) => {
                    return {
                        label: item,
                        value: item,
                    }
                })
                setSchemaState(temp)
            }
            console.log(json, "json")
        })
    }
    useEffect(() => {
        if (!accID) {
            getTags()
        } else {
            getAccDetail()
        }
    }, [accID])
    const [addtag, setAddtag] = useState(false)
    const [tagName, setTagName] = useState("")
    const [tagType, setTagType] = useState("")
    const addTag = () => {
        if (!tagName) {
            message.warning("请输入标签名称")
            return
        }
        if (!tagType) {
            message.warning("请输入标签类型")
            return
        }
        customAxios({
            method: "post",
            url: "/fault_tags",
            data: {
                fault_type: tagType,
                tag: tagName
            }
        }).then((res) => {
            if (res.data && res.data.id) {
                message.success("标签创建成功！")
                getTags()
                setAddtag(false)
            }
        })

    }
    const form = useForm();
    const reset = () => {
        form.setValues({})
    }

    useEffect(() => {
        reset()
    }, [])

    const createAcc = () => {
        const formValues = form.getValues()
        const { type, num, timeOccur, timeDeal, state = 0, deal, operate, detail, timeOccurT = " 00:00:00", timeDealT = " 00:00:00",tags } = formValues

        if (!num) {
            message.warning("设备编号必填！")
            return
        }
        if (!type) {
            message.warning("事故类型必填！")
            return
        }
        if (!timeOccur) {
            message.warning("事故发生时间必填！")
            return
        }
        customAxios({
            method: "post",
            url: "/faults",
            data: {
                attachments: fileList.length > 0 ? fileList.map((item) => { return item.response.id + "|" + item.name }) : [],
                description: detail ? detail : "",
                device: num ? num : "",
                fault_time: timeOccur ? new dayjs(timeOccur + timeOccurT).toISOString() : undefined,
                operation: deal ? deal : "",
                operator: operate ? [operate] : [],
                recover_time: timeDeal ? new dayjs(timeDeal + timeDealT).toISOString() : undefined,
                state: timeDeal ? 1 : parseInt(state),
                // tags: currentTag ? [currentTag] : [],
                tags: tags?[tags]:[],
                type: type ? type : ""
            }
        }).then((res) => {
            callback && callback()
        })

    }
    const updateAcc = () => {
        const formValues = form.getValues()
        const { type, num, timeOccur, timeOccurT = " 00:00:00", timeDeal, timeDealT = " 00:00:00", state = 1, deal, operate, detail,tags } = formValues
        // if (!timeDeal) {
        //     message.warning("事故修复时间必填")
        //     return
        // }
        customAxios({
            method: "put",
            url: `/faults/${accID}`,
            data: {
                attachments: fileList.length > 0 ? fileList.map((item) => { return item.response.id + "|" + item.name }) : [],
                description: detail ? detail : "",
                device: num ? num : "",
                fault_time: timeOccur ? new dayjs(timeOccur + timeOccurT).toISOString() : undefined,
                operation: deal ? deal : "",
                operator: operate ? [operate] : [],
                recover_time: timeDeal ? new dayjs(timeDeal + timeDealT).toISOString() : undefined,
                state: timeDeal ? 1 : parseInt(state),
                tags: tags?[tags]:[],
                type: type ? type : ""
            }
        }).then((res) => {
            callback && callback()
        })
    }

    const ok = () => {
        if (accID) {
            updateAcc()
        } else {
            createAcc()
        }
    }
    const schema = {
        type: 'object',
        displayType: 'row',
        labelWidth: 100,
        properties: {
            type: {
                title: '故障类型',
                type: 'string',
                widget: 'select',
                props: {
                    options: [
                        {
                            label: "未定义", value: "未定义"
                        },
                        { label: "电池", value: "电池" }
                    ]
                }
            },
            tags:{
                title: '故障标签',
                type: 'string',
                widget: 'select',
                props: {
                    options: [
                        {
                            label: "未定义", value: "未定义"
                        }
                    ]
                }
            },
            num: {
                title: '设备编号',
                type: 'string',
                widget: 'input',
            },
            timeOccur: {
                title: "故障发生日期",
                type: "string",
                widget: "datePicker"
            },
            timeOccurT: {
                title: "故障发生时间",
                type: "string",
                format: "time",
                widget: "timePicker"
            },
            timeDeal: {
                title: "故障修复日期",
                type: "string",
                widget: "datePicker"
            },
            timeDealT: {
                title: "故障修复时间",
                type: "string",
                format: "time",
                widget: "timePicker"
            },
            detail: {
                title: "故障详情",
                type: "string",
                widget: "textArea"
            },
            state: {
                title: "状态",
                type: "string",
                widget: "radio",
                props: {
                    options: [
                        {
                            label: "已处理",
                            value: "1"
                        }, {
                            label: "未处理",
                            value: "0"
                        }
                    ]
                }
            },
            deal: {
                title: "处理详情",
                type: "string",
                widget: "textArea"
            },
            operate: {
                title: '操作人',
                type: 'string',
                widget: 'input',
            }
        }
    }
    const [schemaState, setSchemaState] = useState(schema)

    const onFinish = (formData) => {
        console.log('formData:', formData);
    };
    const watch = {
        type: (value) => {
            setCurrentTag("")
            let temp = JSON.parse(JSON.stringify(schemaState))
            temp.properties.tags.props.options = tagsArr.filter((item) => {
                return item.fault_type==value
            }).map((item)=>{
                return {
                    label:item.tag,
                    value:item.tag
                }
            })
            setSchemaState(temp)
            console.log(value, " type value")
        }
    }
    const [currentTag, setCurrentTag] = useState("")
    const pickTag = (item) => {
        setCurrentTag(item.tag)
        form.setValues({ type: item.fault_type })
    }
    const [fileList, setFileList] = useState([])
    const uploadProps = {
        fileList: fileList,
        name: "file",
        action: process.env.REACT_APP_ORIGIN ? process.env.REACT_APP_ORIGIN + "/file" : "https://qiaokou.testing.tsingroc.com/api/file",
        headers: {
            "X-Requested-With": null
        },
        onChange: (info) => {
            setFileList(info.fileList)
        }
    }
    return <div>
        <div className='from-modal-c'>
            {
                !accID ? <div className='mb10' style={{display:"none"}}>
                    <span>故障标签：</span>
                    {
                        tagsArr.map((item, key) => {
                            return <Tag className='cur' color={currentTag == item.tag ? "gold" : ""} key={key} onClick={() => { pickTag(item) }}>{item.tag}</Tag>
                        })
                    }
                    {
                        addtag ? <div className='mt5'>
                            <span>故障类型：</span>
                            <Input
                                value={tagType} style={{ width: "100px", marginRight: "5px" }}
                                onChange={(e) => { setTagType(e.target.value) }}
                            ></Input>
                            <span>故障标签：</span>
                            <Input style={{ width: "100px", marginRight: "5px" }} value={tagName}
                                onChange={(e) => { setTagName(e.target.value) }}>
                            </Input>
                            <Button  type='primary' onClick={addTag}>添加</Button> </div> : <PlusCircleOutlined onClick={() => { setAddtag(true) }} />
                    }
                </div> : <div className='mb10'><span>故障标签：</span>{tagShow.join(",")}</div>
            }

            <FormRender
                form={form}
                schema={schemaState}
                watch={watch}
                onFinish={onFinish}
                footer={false}
            />
            <Upload
                {
                ...uploadProps
                }

                className='ml100'>
                <Button icon={<UploadOutlined />}>点击上传文件</Button>
            </Upload>
        </div>
        <div className='tr'>
            <Button type='primary' onClick={ok}>确定</Button>
        </div>
    </div>
}