import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from './pages/home'
import Battery from './pages/battery'
import Library from './pages/library'
import Data from './pages/data'
import Adjust from './pages/adjust'
import Overhau from './pages/overhau'
import LibraryDetail from './pages/libraryDetail'
import SiteSummarize from './pages/siteSummarize'
import SiteDetail from './pages/siteDetail'
import SiteBattery from './pages/siteBattery'
import SiteSummarize1 from './pages/siteSummarize1'
import React, { useState, useEffect } from 'react'
import SDK from 'casdoor-js-sdk'


import Test from './pages/test'

export const config = {
    serverUrl: "https://login.tsingroc.com",
    clientId: "f5dd7bb38dfc880754c6",
    organizationName: "tsingroc",
    appName: "qiaokou-ai",
    redirectPath: "/callback", // in accordance with casdoor configuration
};

function App() {
    const [sdk, setSdk] = useState(null);
    // 检查localStorage中是否有token，如果没有则跳转到登录页面
    useEffect(() => {
        const initSdk = async () => {
            const initializedSdk = new SDK(config);
            setSdk(initializedSdk);
            // 首先处理Callback页面的逻辑
            if (window.location.href.includes('code')) {
                try {
                    const res = await initializedSdk.signin(window.location.origin);
                    if (res.token) {
                        localStorage.setItem('token', res.token);
                        window.location.href = "/";
                        return;
                    }
                } catch (error) {
                    console.log('Error during signin:', error);
                }
            } else {
                if (!localStorage.getItem('token')) {
                    window.location.href = initializedSdk.getSigninUrl();
                }
            }
        };
        initSdk();
    }, []);

    // 检查localStorage中是否有token，如果没有则提示跳转到登录页
    if (!localStorage.getItem('token') && !window.location.href.includes('code')) {
        return (
            <p>
                跳转登录页...
            </p>
        );
    } else {
        return (
            <div >
                <Routes>
                    <Route path="/test" element={<Test></Test>}></Route>
                    <Route path="/" element={<Home >home</Home>}></Route>
                    <Route path="/battery" element={<Battery></Battery>}></Route>
                    <Route path="/library" element={<Library></Library>}></Route>
                    <Route path="/data" element={<Data></Data>}></Route>
                    <Route path="/agc" element={<Adjust></Adjust>}></Route>
                    <Route path="/maintenance" element={<Overhau></Overhau>}></Route>
                    <Route path="/libraryDetail" element={<LibraryDetail></LibraryDetail>}></Route>

                    {/* 云平台页面 */}
                    <Route path="/clouds" element={<SiteSummarize1></SiteSummarize1>}></Route>
                    <Route path="/cloud" element={<SiteSummarize></SiteSummarize>}></Route>
                    <Route path="/cloud/siteSummarize" element={<SiteSummarize></SiteSummarize>}></Route>
                    <Route path="/cloud/siteDetail" element={<SiteDetail></SiteDetail>}></Route>
                    <Route path="/cloud/siteBattery" element={<SiteBattery></SiteBattery>}></Route>
                </Routes>
            </div>
        );
    }
}

export default App;
