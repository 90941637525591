import { Space, Row, Col } from 'antd'
import { YunComHeader } from '../siteSummarize'
import BatteryCluster from '../../component/BatteryCluster'

import './index.scss'
export default function View() {
    return <div className='site-bat-page'>
        <YunComHeader></YunComHeader>
        <div className='flex mt20 det-box'>
            <div className='flex-1'>
                <div className='l-l-back p10 mr20 '>
                <Bat></Bat>
                </div>
                 </div>
            <div className='flex-440'>
                <div className='l-l-back' style={{ height: "105%" }}>
                    <BattDetail></BattDetail>
                    <div >
                        <Ycxwh></Ycxwh>

                        <Jxsjtx></Jxsjtx>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

//电池簇
function Bat() {
    const cluster = new Array(6).fill({ soc: 0.5 })
    const battery = new Array(24).fill(cluster)
    return <div>
        <Row>
            {
                battery.map((item, key) => {
                    return <Col span={6} className='' key={key} >
                          <div className="tc  cfff mt10">U{key + 1}</div>
                        <Row className='p10 pb0' >
                        {
                            item.map((i,j)=>{
                                return <Col span={8} key={j} className="tc">
                                    <div className=''>
                                    <BatteryCluster></BatteryCluster>
                                    <div className="num-clus cfff">{j + 1}</div>
                                    </div>
                                 </Col>
                            })
                        }
                         </Row>
                    </Col>
                })
            }
        </Row>
    </div>
}

//检修时间提醒
function Jxsjtx() {
    const suggest = "2023-06-12,建议维修如下电池簇"
    const list = ["U2-3", "U3-6"]
    return <div className='p20 pt0'>
        <div className='jxsjtx-h'>
            <Space>
                <svg className='icon f30' aria-hidden="true" >
                    <use xlinkHref={`#icon-shichangtongji`}></use>
                </svg>
                <div> 检修时间提醒</div>
            </Space>
        </div>
        <div className='cfff jxs-b'>
            <div>{suggest}</div>
            <div>
                {
                    list.map((item, key) => {
                        return <div key={key} className='l32'>{item}</div>
                    })
                }
            </div>
        </div>
    </div>
}

//预测性维护
function Ycxwh() {
    const list = [{ name: "U12-1", des: "温度", type: 0 }, { name: "U12-1", des: "温度", type: 1 }]
    return <div className='p20 pt0'>
        <div className='ycxwh-c' >
            <Space>
                <svg className='icon f30' aria-hidden="true" >
                    <use xlinkHref={`#icon-jianxiutai`}></use>
                </svg>
                <div>
                    预测性维护
                </div>
            </Space>
        </div>
        <div className='ycxwh-b'>
            {
                list.map((item, key) => {
                    return <div key={key} className='ml20'>
                        <span className='cfff l32'>{item.name}: </span>
                        <span className={`${item.type == 0 ? "colorY" : "colorR"}`} >{item.des}</span>
                    </div>
                })
            }
        </div>
        <div className='ycxwh-f'>
            <Space> <div className='yc-box-y'></div> <span className='l50 colorY'>预测性故障</span><div className='yc-box-r colorR'></div>真实故障</Space>
        </div>
    </div>
}

//电池工作详情
function BattDetail() {
    const arr = ["SOC", "SOH"]
    const icon = ["icon-dianchi1", "icon-jiankang"]
    return <div className='p20 pb0'>
        {
            arr.map((item, key) => {
                return <div className='dash-b-ch' key={key}>
                    <Space>
                        <div className='mr20'>
                            <svg className='icon f30' aria-hidden="true" >
                                <use xlinkHref={`#${icon[key]}`}></use>
                            </svg>
                        </div>
                        <div className='mr20'><span className='cfff'>电池平均</span><span>{item}</span></div>
                        <div><span className='l70 f32'>96</span>%<span></span> </div>
                    </Space>
                </div>
            })
        }
    </div>
}