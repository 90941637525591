import { Divider, Popconfirm, Space } from "antd";
import { useNavigate } from 'react-router-dom'
import {useEffect, useState} from 'react'
import SDK from 'casdoor-js-sdk';
import jwt_decode from "jwt-decode";
import {UserOutlined} from '@ant-design/icons'

import { useGetPathname } from "../../customEffect"
import {config} from '../../App'

import './index.scss';


  

export default function View(props){
    const router=useNavigate()
    const currentTab=useGetPathname()
    const  decoded = jwt_decode(localStorage.getItem("token"));
    const initializedSdk = new SDK(config);
    //退出
    const quit=()=>{
        localStorage.removeItem("token")
        window.location.href = initializedSdk.getSigninUrl();
    }

    const tabObjectArr=[{name:"首页",path:""},{name:"电池",path:"battery"},
    {name:"联合调频",path:"agc"},
    // {name:"数据操作",path:"data"},
    {name:"故障知识库",path:"library"},{name:"检修记录",path:"maintenance"}]
  
    return <div className="header-tab">
        <div className="title-ic">
            <span style={{color:"#1890FF"}}>
            <svg className='icon' aria-hidden="true" >
                            <use xlinkHref={`#icon-Management_system`}></use>
                        </svg>
            </span>
           <span>   火储联合调频监控平台</span> </div>
        <div className="tab-ul">
        <Space>
        {
            tabObjectArr.map((item,key)=>{
                return <div  onClick={()=>{router(`/${item.path}`)}} className={`${currentTab==item.path||(currentTab=="libraryDetail"&&key==3)?"current com-title":"com-title"}`}  key={key}>{item.name}</div>
            })
           
        }
         <div style={{marginLeft:"20px" ,color:"rgb(24, 144, 255)"}}>
            <Popconfirm title="确定退出？" onConfirm={quit}>
            <UserOutlined /> {decoded.displayName}
            </Popconfirm>
           </div>
        </Space>
        </div>
        <Divider style={{margin:"0"}}></Divider>
    </div>
}