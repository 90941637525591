import { Slider } from "antd"
import { useEffect, useState } from "react"
export default function View(){
    const [min,setMin]=useState(0)
    const [max,setMax]=useState(100)
    const [slideValue,setSlideValue]=useState([20,50])
    const slideChange=(value)=>{
        //右移，临近右边界
        if(value[1]>max-2){
            let maxT=max*1.2
            setMax(maxT)
        }
        setSlideValue(value)
        console.log(value,"value")
    }
  
    const onAfterChange=(value)=>{
        console.log("onAfterChange")
    }
    return <div>
        datePick
        <div>min:{min}</div>
        <div>max:{max}</div>
        <Slider min={min} max={max} range value={slideValue} onChange={(value)=>{
            slideChange(value)
        }}
        onAfterChange={onAfterChange}
        >
        </Slider>
    </div>
}