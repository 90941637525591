import { Space } from 'antd'
import {YunComHeader} from '../siteSummarize'
import './index.scss'
export default  function View(){
    return <div className='siteDetail-page'>
         <YunComHeader></YunComHeader>
         <div className='flex mt20 det-box'>
            <div className='flex-440'>
                <div className='l-l-back h-40p'>
                <BaseInfo></BaseInfo>
                </div>
                <div className='l-l-back h-60p'>
                    <RunScen></RunScen>
                </div>
            </div>
            <div className='flex-1'></div>
            <div className='flex-440'>
                <div className='l-l-back h-100p'>
                    <Yyzb></Yyzb>
                </div>
                <div>
                        <Space>
                            <div className='det-button mr30'>电站运行详情</div>
                            <div className='det-button'>电站数据导出</div>
                        </Space>
                    </div>
            </div>
         </div>
    </div>
}

//运营指标
function Yyzb(){
    const arr=["年度运行小时","中标小时","项目平均KP值","总调频里程","电池平均SOH"]
    const unit=["小时","小时","","小时","%"]
    const icon=["icon-shichangtongji","icon-querenzhongbiao","icon-zhibiao",'icon-licheng','icon-jiankang']
    const color=["rgba(82, 196, 26, 0.1)","rgba(255, 77, 79, 0.1)","rgba(0, 147, 255, 0.1)","rgba(0, 147, 255, 0.1)","rgba(82, 196, 26, 0.1)"]
    const fontColor=["#52C41A","#FF4D4F","#1890FF","#FAAD14","#52C41A"]
    return <div>
        <div className='card-t'>运营指标</div>
        <div className='ml20'>
            {
                arr.map((item,key)=>{
                    return <div style={{background:color[key]}} className='ru-c-item' key={key}>
                        <Space>
                            <div className='mr40'>
                            <svg className='icon f30' aria-hidden="true" >
                                                    <use xlinkHref={`#${icon[key]}`}></use>
                                                </svg> 
                            </div>
                            <div>{item}</div>
                            <div style={{color:fontColor[key]}}><span className='f32 l70'>168</span> <span className='f12'>{unit[key]}</span></div>
                        </Space>

                    </div>
                })
            }
        </div>
    </div>

}

//项目运行情况
function RunScen(){
    const arr=["电池平均SOC","电网调度","火电出力","储能出力"]
    const color=["rgba(82, 196, 26, 0.1)","rgba(0, 147, 255, 0.1)","rgba(255, 77, 79, 0.1)","rgba(250, 173, 20, 0.1)"]
    const icon=["icon-dianchi1","icon-zu","icon-huodian","icon-chuneng"]
    const fontColor=["#52C41A","#1890FF","#FF4D4F","#FAAD14"]
    const unit=["%","MW","MW","MW"]
    return <div>
        <div className='card-t'>项目运行情况</div>
        <div className='ml40'>
        {
            arr.map((item,key)=>{
                return <div style={{background:color[key]}} className='ru-c-item' key={key}>
                    <Space><div className='mr40'>
                    <svg className='icon f30' aria-hidden="true" >
                                                    <use xlinkHref={`#${icon[key]}`}></use>
                                                </svg> 
                        </div> <div>{item}</div> <div style={{color:fontColor[key]}}><span className='f32 l70'>168</span> <span className='f12'>{unit[key]}</span></div> </Space> 
                    </div>
            })
        }
        </div>
    </div>
}

//电站详情
function BaseInfo(){
    const arr=["项目名称","装机容量","投产时间","电池类型","地点","参与市场"]
    const value=["华润电力","18MW","2019","三元锂电池","湖南郴州","广东市场"]
    return <div >
        <div className='card-t'>电站详情</div>
        <div className='ml20 l32'>
        {
            arr.map((item,key)=>{
                return <div key={key}><span className='des-t'>{item}:</span> <span className='des-d'>{value[key]}</span></div>
            })
        }
        </div>
    </div>
}